import React from "react";
import { StyledLoginButton } from "./LoginButton.styled";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../../components/Typography/Typography";

type GoogleButtonProps = {
  icon: string;
  onCLick: () => void;
  label: string;
};

export const LoginButton = ({ icon, onCLick, label }: GoogleButtonProps) => {
  return (
    <StyledLoginButton onClick={onCLick}>
      <img src={icon} alt={`${label} icon`} style={{ width: 24, height: 24 }} />
      <Typography
        fontSize={FontSizeEnum.SM}
        fontWeight={FontWeightEnum.REGULAR}
      >
        {`Login with ${label}`}
      </Typography>
    </StyledLoginButton>
  );
};
