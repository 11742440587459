import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ChooseDataProps } from "../../../../types/apiTypes";
import { ChooseCard } from "../ChooseCard/ChooseCard";

type ChooseSwiperProps = {
  data: ChooseDataProps[];
  handleApprove: (id: string) => void;
};

export const ChooseSwiper = ({ data, handleApprove }: ChooseSwiperProps) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {data.map((el, i) => (
        <SwiperSlide key={`${el.id}${el.title}${i}`}>
          <ChooseCard data={el} handleApprove={handleApprove} showButtons />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
