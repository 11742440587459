import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hook/useAuth";
import { PageNames } from "../types/appTypes";
import { RolesEnum } from "../types/appTypes";

export const PrivateRoute = () => {
  const { userLogged, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userLogged) {
    return <Navigate to="/" />;
  }

  if (
    userLogged.role === RolesEnum.USER &&
    location.pathname !== PageNames.CHOOSE
  ) {
    return <Navigate to={PageNames.CHOOSE} />;
  }
  if (
    (userLogged.role === RolesEnum.ADMIN ||
      userLogged.role === RolesEnum.FREELANCE) &&
    location.pathname === PageNames.CHOOSE
  ) {
    return <Navigate to={PageNames.DASHBOARD} />;
  }

  return <Outlet />;
};
