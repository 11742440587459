/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { AxiosResponse, AxiosError } from "axios";
import { apiClient } from "../api/axiosInstance";

type UseApiResult<T> = {
  response: T | null;
  isLoading: boolean;
  error: string | null;
};

export const useApi = <T = any,>(
  url: string,
  dependencies: any[] = [],
): UseApiResult<T> => {
  const [response, setResponse] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const result: AxiosResponse<T> = await apiClient.get(url);
        setResponse(result.data);
      } catch (err) {
        const axiosError = err as AxiosError;
        if (axiosError.response) {
          setError(
            `Error ${axiosError.response.status}: ${axiosError.response.data}`,
          );
        } else if (axiosError.request) {
          setError("No response from the server.");
        } else {
          setError(axiosError.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, ...dependencies]);
  return { response, isLoading, error };
};
