import React from "react";
import { ChooseDataProps } from "../../../../types/apiTypes";
import { ChooseContainer } from "../ChooseContainer/ChooseContainer";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../../../components/Typography/Typography";
import { PUBLICATION_DATE_LABEL } from "../../../../constants/dictionary";
import { FORMAT_DATE } from "../../../../constants/AppConstants";
import dayjs from "dayjs";
import { Divider } from "../../../../components/Divider/Divider";
import { useAppSelector } from "../../../../hook/Store";
import { StyledChooseCard } from "./ChooseCard.styled";

type ChooseCardProps = {
  data: ChooseDataProps;
  handleApprove?: (id: string) => void;
  showButtons?: boolean;
};

export const ChooseCard = ({
  data,
  handleApprove,
  showButtons,
}: ChooseCardProps) => {
  const { language } = useAppSelector((state) => state.user);
  return (
    <ChooseContainer
      handleApprove={handleApprove}
      id={data.id}
      showButtons={showButtons}
    >
      <StyledChooseCard>
        <div>
          <Typography
            fontWeight={FontWeightEnum.SEMIBOLD}
            fontSize={FontSizeEnum.xxl}
          >
            {data.title}
          </Typography>
          <Typography
            fontWeight={FontWeightEnum.REGULAR}
            fontSize={FontSizeEnum.xs}
          >
            {PUBLICATION_DATE_LABEL[language]}
            <Typography
              fontWeight={FontWeightEnum.MEDIUM}
              fontSize={FontSizeEnum.xs}
            >
              {dayjs(data.date, FORMAT_DATE).format(FORMAT_DATE)}
            </Typography>
          </Typography>
        </div>
        <Divider />
        <Typography
          fontWeight={FontWeightEnum.REGULAR}
          fontSize={FontSizeEnum.SM}
        >
          {data.text}
        </Typography>
      </StyledChooseCard>
    </ChooseContainer>
  );
};
