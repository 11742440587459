import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

type StyledLinkProps = {
  $underline?: boolean;
  color?: string;
  $disabled?: boolean;
};
export const StyledLink = styled.div<StyledLinkProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};

  text-decoration: ${({ $underline }) => ($underline ? "underline" : "none")};

  text-decoration-color: ${({ $disabled, color }) =>
    $disabled ? theme.colors.grey_2 : color ? color : theme.colors.black};
  &:hover {
    text-decoration-color: ${({ $disabled, color }) =>
      $disabled ? theme.colors.grey_2 : color ? color : theme.colors.purple};

    & span {
      color: ${({ $disabled, color }) =>
        $disabled ? theme.colors.grey_2 : color ? color : theme.colors.purple};
    }

    & svg {
      fill: ${({ $disabled, color }) =>
        $disabled ? theme.colors.grey_2 : color ? color : theme.colors.purple};
    }
  }
`;
