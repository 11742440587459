export const theme = {
  colors: {
    purple: "#7B5EED",
    purple_hover: "#5631E8",
    purple_2: "#ECE8FC",
    purple_3: "#B7A8F3",
    grey_2: "#74747B",
    grey_4: "#C5C6C9",
    black: "#0E0E0E",
    white: "#ffffff",
    page_background: "#f8f8f8",
    primary: "#031434",
    red: "red",
    green: "green",
  },
  variables: {
    sidebar_big_width: "280px",
    sidebar_hide_width: "84px",
    border_radius_1: "8px",
    height_1: "32px",
    height_3: "48px",
    height_4: "50px",
    height_5: "18px",
    height_6: "24px",
    height_7: "16px",
    header_height: "60px",
    fw_semiBold: "600",
    fs_22: "22px",
    fs_14: "14px",
    user_panel_width: "265px",
    sw_1: "1px",
    sw_2: "1.5px",
    sw_3: "1.8px",
    sw_4: "2px",
    // eslint-disable-next-line quotes
    font_family: '"Work Sans", sans-serif',
  },
  breakpoints: {
    SM: 640,
    MD: 768,
    LG: 1024,
    XL: 1280,
    XXL: 1536,
  },
};
