import styled from "styled-components";
import { theme } from "../../../constants/StyledComponents";

export const StyledLoginCard = styled.div`
  background-color: #b7a8f3;
  border-radius: 12px;
  border: 1px solid #757775;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 75px 0 25px;
  width: 320px;
  margin: 0 auto;
  transform: translateY(calc(50vh - 50%));
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  & > span {
    margin-bottom: 30px;
  }
`;
export const StyledUserIconLogin = styled.div`
  background-color: ${theme.colors.purple_hover};
  width: 100px;
  height: 100px;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 54px;
  position: absolute;
  top: -50%;
  transform: translateY(60%);
`;
