import styled from "styled-components";
import { theme } from "../../../constants/StyledComponents";

export const StyledInvitedView = styled.div`
  height: 100%;
  background-color: ${theme.colors.page_background};
  & .page-container {
    background-color: ${theme.colors.page_background};
    height: calc(100% - ${theme.variables.header_height} - 24px);
    overflow: auto;
    padding: 0 32px 12px;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: ${theme.breakpoints.MD}px) {
    & .page-container {
      padding: 0 15px 12px;
    }
  }
`;
