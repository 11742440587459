import React, { useState } from "react";
import { Header } from "../../Header/Header";
import { Outlet } from "react-router";
import { StyledInvitedView } from "./InvitedView.styled";
import { useDimensions } from "../../../hook/useDimensions";
import { theme } from "../../../constants/StyledComponents";

export const InvitedView = () => {
  const { width } = useDimensions();
  const isTablet = width <= theme.breakpoints.LG;
  const [showSidebar, setShowSidebar] = useState(!isTablet);
  return (
    <StyledInvitedView>
      <Header
        isChoose
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div className="page-container">
        <Outlet />
      </div>
    </StyledInvitedView>
  );
};
