import React from "react";
import { StyledFilterButtons } from "./FIlterButtons.styled";
import { useAppSelector } from "../../hook/Store";
import { OptionItem } from "../../constants/AppConstants";

type FilterButtonsProps = {
  options: OptionItem[];
  selectedOption: OptionItem | null;
  setSelectedOption: (x: OptionItem) => void;
  disabled?: boolean;
};

export const FilterButtons = ({
  options,
  selectedOption,
  setSelectedOption,
  disabled,
}: FilterButtonsProps) => {
  const { language } = useAppSelector((state) => state.user);
  return (
    <StyledFilterButtons $disabled={disabled}>
      {options.map((option) => (
        <span
          className={`${selectedOption && selectedOption.value === option.value && "active"}`}
          key={option.value}
          onClick={disabled ? () => {} : () => setSelectedOption(option)}
        >
          {option.label[language]}
        </span>
      ))}
    </StyledFilterButtons>
  );
};
