import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

type StyledSidebarItemsCreatorProps = {
  $showSidebar: boolean;
};

export const StyledSidebarItemsCreator = styled.div<StyledSidebarItemsCreatorProps>`
  &:last-child {
    margin-bottom: 20px;
  }
  & .item-sidebar {
    display: flex;
    gap: 10px;
    padding: 8px 10px;
    border-radius: ${theme.variables.border_radius_1};
    cursor: pointer;
    align-items: center;
    margin-bottom: 15px;
    justify-content: ${({ $showSidebar }) =>
      $showSidebar ? "flex-start" : "center"};
  }
  & .item-sidebar:hover,
  & .item-sidebar.active {
    background-color: ${theme.colors.purple};
    font-weight: ${theme.variables.fw_semiBold};
    color: white;
  }
  & .item-sidebar:hover span,
  & .item-sidebar.active span {
    color: white;
    font-weight: ${theme.variables.fw_semiBold};
  }
  & .item-sidebar svg path {
    stroke: ${theme.colors.black};
  }
  & .item-sidebar:hover svg path,
  & .item-sidebar.active svg path {
    stroke: white;
  }
`;
