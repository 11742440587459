import React from "react";
import { SIDEBAR_MENU_ITEMS } from "../../constants/SidebarConstants";
import { StyledCloseIcon, StyledSidebar } from "./Sidebar.styled";
import packageJson from "../../../package.json";
import { SidebarItemsCreator } from "../SidebarItemsCreator/SidebarItemsCreator";
import { useAppSelector } from "../../hook/Store";
import { IconManager } from "../IconManager/IconManager";
import { IoCloseSharp } from "react-icons/io5";
import { useDimensions } from "../../hook/useDimensions";
import { theme } from "../../constants/StyledComponents";
import { IconSizeEnum } from "../../types/appTypes";

type SidebarProps = {
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
};

export const Sidebar = ({ showSidebar, setShowSidebar }: SidebarProps) => {
  const { language, userLogged } = useAppSelector((state) => state.user);
  const { width } = useDimensions();
  const isMobile = width <= theme.breakpoints.MD;

  return (
    <StyledSidebar $showSidebar={showSidebar}>
      {showSidebar ? (
        <>
          {isMobile && (
            <StyledCloseIcon onClick={() => setShowSidebar(false)}>
              <IconManager size={IconSizeEnum.XSMALL}>
                <IoCloseSharp />
              </IconManager>
            </StyledCloseIcon>
          )}
          <div className="title-app">FirstApp</div>
        </>
      ) : (
        <div className="title-app">F</div>
      )}
      {userLogged && (
        <div className="items-container">
          <SidebarItemsCreator
            items={SIDEBAR_MENU_ITEMS(language, userLogged.role)}
            showSidebar={showSidebar}
            closeSidebar={() => setShowSidebar(false)}
          />
        </div>
      )}
      <div className="bottom-container">
        {showSidebar && "Version "}
        {`V${packageJson.version}`}
      </div>
    </StyledSidebar>
  );
};
