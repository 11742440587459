import { GOOGLE_LABEL } from "./dictionary";
import { LanguageEnum } from "../types/appTypes";
import googleIcon from "../assets/google.png";

export const loginButtons = (language: LanguageEnum) => [
  {
    label: GOOGLE_LABEL[language],
    onclick: () =>
      (window.location.href = `${process.env.REACT_APP_BE_DOMAIN}/auth/google`),
    background: "#db4a39",
    key: "google",
    icon: googleIcon,
  },
];
