import React from "react";
import { StyledNoDataInserted } from "./NoDataInserted.styled";
import {
  ADD_LABEL,
  NO_CLIENTS_PRESENT_LABEL,
  NO_PROJECTS_PRESENT_LABEL,
} from "../../constants/dictionary";
import { useAppSelector } from "../../hook/Store";
import { Button, ButtonSizes, ButtonVariants } from "../Button/Button";
import { IoMdAdd } from "react-icons/io";

type NoDataInsertedProps = {
  isClients?: boolean;
  onClick: () => void;
};

export const NoDataInserted = ({ isClients, onClick }: NoDataInsertedProps) => {
  const { language } = useAppSelector((state) => state.user);
  return (
    <StyledNoDataInserted>
      {isClients
        ? NO_CLIENTS_PRESENT_LABEL[language]
        : NO_PROJECTS_PRESENT_LABEL[language]}
      <Button
        variant={ButtonVariants.SECONDARY}
        size={ButtonSizes.REGULAR}
        label={ADD_LABEL}
        onClick={onClick}
        icon={<IoMdAdd />}
        color="black"
      />
    </StyledNoDataInserted>
  );
};
