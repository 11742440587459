import React from "react";
import { Modal } from "antd";

type CustomModalProps = {
  open: boolean;
  title?: string;
  onOk?: () => void;
  onCancel: () => void;
  children: string | React.ReactNode;
  showFooter?: boolean;
  height?: string;
};

export const CustomModal = ({
  open,
  title,
  onOk,
  onCancel,
  children,
  showFooter,
  height,
}: CustomModalProps) => {
  return (
    <Modal
      height={height || ""}
      centered
      open={open || false}
      title={title ? title : ""}
      onOk={onOk}
      onCancel={onCancel}
      footer={showFooter ? undefined : null}
    >
      {children}
    </Modal>
  );
};
