import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

type StyledSidebarProps = {
  $showSidebar: boolean;
};

export const StyledSidebar = styled.div<StyledSidebarProps>`
  margin-top: 12px;
  box-sizing: border-box;
  padding: 28px 20px;
  z-index: 99;
  background-color: white;
  position: relative;
  width: ${({ $showSidebar, theme }) =>
    $showSidebar
      ? theme.variables.sidebar_big_width
      : theme.variables.sidebar_hide_width};
  min-width: ${({ $showSidebar, theme }) =>
    $showSidebar
      ? theme.variables.sidebar_big_width
      : theme.variables.sidebar_hide_width};
  height: 100vh;
  display: flex;
  flex-direction: column;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: ${theme.variables.border_radius_1}
    ${theme.variables.border_radius_1} 0 0;
  box-shadow: 0px 2px 8px 0px rgba(74, 74, 79, 0.08);
  & .item-sidebar .icon {
    margin: ${({ $showSidebar }) => ($showSidebar ? "3px 14px 3px 0" : 0)};
    height: 1em;
    width: 1.5em;
  }
  & .title-app {
    text-align: ${({ $showSidebar }) => ($showSidebar ? "left" : "center")};
  }
  & .items-container {
    margin: 36px 0 36px 0;
    flex: 1;
    min-height: 200px;
    overflow-y: overlay;
    overflow-x: hidden;
  }

  & .bottom-container {
    padding: 24px 0 24px 0;
    border-top: 1px solid ${theme.colors.purple_2};
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .open-menu-container {
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${theme.breakpoints.MD}px) {
    transform: ${({ $showSidebar }) =>
      $showSidebar ? "translateX(0)" : "translateX(-100%)"};
    left: 0;
    position: fixed;
  }
`;
export const StyledCloseIcon = styled.div`
  border-radius: 100%;
  background-color: ${theme.colors.white};
  padding: 4px;
  position: absolute;
  right: -14px;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px rgba(74, 74, 79, 0.08);
`;
