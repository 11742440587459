import React, { MouseEventHandler } from "react";
import { StyledIconManager } from "./IconManager.styled";
import { IconSizeEnum } from "../../types/appTypes";

type IconManagerProps = {
  children: React.ReactNode;
  size: IconSizeEnum;
  color?: string;
  marginRight?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
export const IconManager = ({
  children,
  size,
  color,
  marginRight,
  onClick,
}: IconManagerProps) => {
  return (
    <StyledIconManager
      size={size}
      color={color}
      $marginRight={marginRight}
      onClick={onClick}
    >
      {children}
    </StyledIconManager>
  );
};
