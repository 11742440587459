import React from "react";
import { StyledLink } from "./Link.styled";
import { IconManager } from "../IconManager/IconManager";
import { IconSizeEnum, LinkSizeEnum } from "../../types/appTypes";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../Typography/Typography";

type StyledLinkProps = {
  children: React.ReactNode;
  onClick?: () => void;
  underline?: boolean;
  icon?: React.ReactNode;
  color?: string;
  size: LinkSizeEnum;
  disabled?: boolean;
};

export const Link = ({
  children,
  onClick,
  underline,
  icon,
  color,
  disabled,
  size,
}: StyledLinkProps) => {
  const dimension =
    size === LinkSizeEnum.REGULAR
      ? FontWeightEnum.REGULAR
      : FontWeightEnum.MEDIUM;
  return (
    <StyledLink
      onClick={disabled ? () => null : onClick}
      $underline={underline}
      color={color}
      $disabled={disabled}
    >
      {icon && (
        <IconManager
          color={color}
          onClick={disabled ? () => null : onClick}
          size={IconSizeEnum.MEDIUM}
        >
          {icon}
        </IconManager>
      )}
      <Typography
        fontSize={FontSizeEnum.MD}
        fontWeight={dimension}
        color={color}
      >
        {children}
      </Typography>
    </StyledLink>
  );
};
