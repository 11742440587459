import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

export const StyledUserPanel = styled.div`
  padding: 8px;
  border-radius: ${theme.variables.border_radius_1};
  border-color: white;
  min-width: ${theme.variables.user_panel_width};
  box-sizing: border-box;
  & .avatar-name-wrapper {
    cursor: pointer;
    margin: 15px 0px 15px 0px;
  }
  & .link-list {
    margin-bottom: 15px;
  }
  & .language-selector {
    display: flex;
    flex-direction: column;
  }
  & .language-selector > div {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  & .language-selector > div > img {
    cursor: pointer;
  }
`;
