import React, { useState } from "react";
import { ChooseDataProps } from "../../../../types/apiTypes";
import { StyledChooseCardList } from "./ChooseCardList.styled";
import { ChooseCard } from "../ChooseCard/ChooseCard";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";

type ChooseCardProps = {
  data: ChooseDataProps[];
  handleApprove: (id: string) => void;
};

export const ChooseCardList = ({ data, handleApprove }: ChooseCardProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [clicked, setClicked] = useState<ChooseDataProps>();
  const handleClicked = (el: ChooseDataProps) => {
    setClicked(el);
    setOpenModal(true);
  };
  const handleClickApprove = (id: string) => {
    handleApprove(id);
    setOpenModal(false);
  };
  return (
    <StyledChooseCardList>
      {data.map((el, i) => (
        <div key={`${el.id}${el.title}${i}`} onClick={() => handleClicked(el)}>
          <ChooseCard data={el} />
        </div>
      ))}
      <CustomModal
        onOk={() => null}
        onCancel={() => setOpenModal(false)}
        open={openModal}
      >
        {clicked && (
          <ChooseCard
            data={clicked}
            handleApprove={handleClickApprove}
            showButtons
          />
        )}
      </CustomModal>
    </StyledChooseCardList>
  );
};
