import styled, { css } from "styled-components";
import { MouseEventHandler } from "react";
import { IconSizeEnum } from "../../types/appTypes";
import { theme } from "../../constants/StyledComponents";

type StyledIconManagerProps = {
  size?: IconSizeEnum;
  color?: string;
  $marginRight?: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const sizes = (size = IconSizeEnum.SMALL) =>
  ({
    xsmall: css`
      width: ${theme.variables.height_7};
      height: ${theme.variables.height_7};
    `,
    small: css`
      width: ${theme.variables.height_5};
      height: ${theme.variables.height_5};
    `,
    medium: css`
      width: ${theme.variables.height_6};
      height: ${theme.variables.height_6};
    `,
    large: css`
      width: ${theme.variables.height_1};
      height: ${theme.variables.height_1};
    `,
  })[size];

const marginVariant = (marginRight: number = 0) => css`
  margin-right: ${marginRight}px;
`;

export const StyledIconManager = styled.div<StyledIconManagerProps>`
  ${({ size }) => sizes(size)};
  ${({ $marginRight }) => marginVariant($marginRight)};
  cursor: ${(props) => (props.onClick ? "pointer" : "inherit")};

  & svg {
    stroke: ${({ color }) => (color ? color : theme.colors.black)};
    ${({ size }) => sizes(size)};
  }
`;
