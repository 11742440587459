import React, { useState } from "react";
import { BuyDataType, PageNames } from "../../types/appTypes";
import { useAppSelector, useAppDispatch } from "../../hook/Store";
import { updateUser, apiClient } from "../../api/axiosInstance";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../actions";
import { BuyTable } from "./BuyTable/BuyTable";
import {
  BUY_LABEL,
  BUY_PROJECTS_LABEL,
  PROJECT_LABEL,
} from "../../constants/dictionary";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from "../../components/Button/Button";
import { StyledTitleContaier } from "../../components/Tables/ClientsTable/ClientsTable.styled";

export const Buy = () => {
  const { userLogged, language } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<BuyDataType[]>([
    {
      id: uuidv4(),
      nameProject: `${PROJECT_LABEL[language]} 1`,
      modules: [],
      price: 25,
    },
  ]);
  const handleSaveAll = async () => {
    try {
      const projectsWithDates = dataSource.map((project) => ({
        idProject: project.id,
        nameProject: project.nameProject,
        startDate: new Date(),
        endDate: dayjs().add(1, "month").toDate(),
        modules: project.modules,
        price: project.price.toString(),
      }));

      await updateUser(userLogged ? userLogged._id : "", {
        license: projectsWithDates,
      });

      const res = await apiClient.get("/api/current_user");
      if (res.data) {
        dispatch(getUser(res.data));
      } else {
        dispatch(getUser(false));
      }

      navigate(PageNames.PROJECTS);
    } catch (err) {
      alert("Failed to update user");
      console.error(err);
    }
  };

  return (
    <>
      <StyledTitleContaier>
        <h1>{BUY_PROJECTS_LABEL[language]}</h1>
      </StyledTitleContaier>
      <BuyTable dataSource={dataSource} setDataSource={setDataSource} />
      <Button
        variant={ButtonVariants.SECONDARY}
        size={ButtonSizes.REGULAR}
        label={BUY_LABEL}
        onClick={handleSaveAll}
        color="black"
      />
    </>
  );
};
