import { Popconfirm } from "antd";
import { BuyDataType, LanguageEnum, ModulesEnum } from "../types/appTypes";
import { PROJECT_NAME_LABEL, MODULES_LABEL, PRICE_LABEL } from "./dictionary";

export const defaultBuyColumns = (
  language: LanguageEnum,
  dataSource: BuyDataType[],
  handleDelete: (x: string) => void,
) => [
  {
    title: PROJECT_NAME_LABEL[language],
    dataIndex: "nameProject",
    editable: true,
    width: "25%",
  },
  {
    title: MODULES_LABEL[language],
    dataIndex: "modules",
    editable: true,
    width: "25%",
  },
  {
    title: PRICE_LABEL[language],
    dataIndex: "price",
    width: "25%",
  },
  {
    title: "operation",
    dataIndex: "operation",
    width: "25%",
    render: (_: string, record: BuyDataType) =>
      dataSource.length >= 1 ? (
        <Popconfirm
          title="Sure to delete?"
          onConfirm={() => handleDelete(record.id)}
        >
          <a>Delete</a>
        </Popconfirm>
      ) : null,
  },
];

export const modulesOptions = [
  {
    label: ModulesEnum.ADS,
    value: ModulesEnum.ADS,
    price: 25,
  },
  {
    label: ModulesEnum.SMM,
    value: ModulesEnum.SMM,
    price: 25,
  },
];
