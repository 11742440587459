import React from "react";
import { ProjectsData } from "../types/apiTypes";
import { Link } from "../components/Link/Link";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../components/Typography/Typography";
import {
  CANCEL_LABEL,
  CUSTOMER_LABEL,
  DELETE_LABEL,
  EDIT_LABEL,
  EDITORIAL_CALENDAR_LABEL,
  MIN_POSTS_LABEL,
  NAME_LABEL,
  SAVE_LABEL,
  SELECT_CALENDAR_LABEL,
  SELECT_CUSTOMER_LABEL,
  SELECT_SOCIAL_LABEL,
  SOCIAL_LABEL,
  STATIC_POSTS_LABEL,
  VIDEO_POSTS_LABEL,
} from "./dictionary";
import {
  CalendarFrequencyEnum,
  CalendarFrequencyLabels,
  LanguageEnum,
  LinkSizeEnum,
  SocialPlatformEnum,
} from "../types/appTypes";
import { Popconfirm } from "antd";
import { CustomSelect } from "../components/CustomSelect/CustomSelect";
import { Chips } from "../components/Chips/Chips";
import { StyledTitleContaier } from "../components/Tables/ClientsTable/ClientsTable.styled";

export const DEFAULT_PROJECTS_TABLE = {
  id: "",
  name: "",
  client: "",
  calendarFrequency: null,
  social: null,
  staticPost: 0,
  videoPost: 0,
  minPost: 0,
};

export const socialPlatformOptions = Object.values(SocialPlatformEnum).map(
  (platform) => ({
    label: platform as SocialPlatformEnum, // Convertiamo `label` a `SocialPlatformEnum`
    value: platform as SocialPlatformEnum,
  }),
);
export const calendarFrequencyOptions = (language: LanguageEnum) =>
  Object.entries(CalendarFrequencyLabels).map(([key, label]) => ({
    label: label[language],
    value: key as CalendarFrequencyEnum,
  }));

export const ProjectsColumns = (
  isEditing: (record: ProjectsData) => boolean,
  language: LanguageEnum,
  save: (id: string) => Promise<void>,
  cancel: () => void,
  edit: (
    record: Partial<ProjectsData> & {
      id: string;
    },
  ) => void,
  disabled: boolean,
  handleDelete: (id: string) => void,
  handleUpdateCalendarFrequency: (
    id: string,
    value: CalendarFrequencyEnum,
  ) => void,
  handleUpdateSocialPlatforms: (
    id: string,
    value: SocialPlatformEnum[],
  ) => void,
  handleUpdateClient: (projectId: string, clientId: string) => void,
  clientOptions: {
    label: string;
    value: string;
  }[],
) => [
  {
    title: NAME_LABEL[language],
    dataIndex: "name",
    width: "15%",
    editable: true,
  },
  {
    title: CUSTOMER_LABEL[language],
    dataIndex: "client",
    width: "15%",
    editable: true,
    render: (_: string, record: ProjectsData) => (
      <CustomSelect
        disabled={!isEditing(record)}
        placeholder={SELECT_CUSTOMER_LABEL[language]}
        options={clientOptions}
        value={record.client || undefined}
        onChange={(value) => handleUpdateClient(record.id, value)}
      />
    ),
  },
  {
    title: EDITORIAL_CALENDAR_LABEL[language],
    dataIndex: "calendarFrequency",
    width: "15%",
    editable: true,
    render: (_: string, record: ProjectsData) => {
      return (
        <CustomSelect<CalendarFrequencyEnum>
          placeholder={SELECT_CALENDAR_LABEL[language]}
          options={calendarFrequencyOptions(language)}
          value={record.calendarFrequency}
          onChange={(value) => handleUpdateCalendarFrequency(record.id, value)}
        />
      );
    },
  },
  {
    title: SOCIAL_LABEL[language],
    dataIndex: "social",
    width: "15%",
    editable: true,
    render: (_: string, record: ProjectsData) => (
      <Chips<SocialPlatformEnum>
        disabled={!isEditing(record)}
        placeholder={SELECT_SOCIAL_LABEL[language]}
        value={record.social}
        options={socialPlatformOptions}
        onChange={(value) => handleUpdateSocialPlatforms(record.id, value)}
      />
    ),
  },
  {
    title: STATIC_POSTS_LABEL[language],
    dataIndex: "staticPost",
    width: "5%",
    editable: true,
  },
  {
    title: VIDEO_POSTS_LABEL[language],
    dataIndex: "videoPost",
    width: "5%",
    editable: true,
  },
  {
    title: MIN_POSTS_LABEL[language],
    dataIndex: "minPost",
    width: "5%",
    editable: true,
  },
  {
    title: "",
    dataIndex: "id",
    width: "10%",
    render: (_: string, record: ProjectsData) => {
      const editable = isEditing(record);
      return (
        <>
          {editable ? (
            <span>
              <Link
                onClick={() => save(record.id)}
                underline
                size={LinkSizeEnum.SMALL}
              >
                <Typography
                  fontWeight={FontWeightEnum.REGULAR}
                  fontSize={FontSizeEnum.SM}
                >
                  {SAVE_LABEL[language]}
                </Typography>
              </Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>
                  <Typography
                    fontWeight={FontWeightEnum.REGULAR}
                    fontSize={FontSizeEnum.SM}
                  >
                    {CANCEL_LABEL[language]}
                  </Typography>
                </a>
              </Popconfirm>
            </span>
          ) : (
            <StyledTitleContaier>
              <Link
                onClick={() => edit(record)}
                disabled={disabled}
                size={LinkSizeEnum.SMALL}
              >
                <Typography
                  fontWeight={FontWeightEnum.REGULAR}
                  fontSize={FontSizeEnum.SM}
                  color="green"
                >
                  {EDIT_LABEL[language]}
                </Typography>
              </Link>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record.id)}
              >
                <a>
                  <Typography
                    fontWeight={FontWeightEnum.REGULAR}
                    fontSize={FontSizeEnum.SM}
                    color="red"
                  >
                    {DELETE_LABEL[language]}
                  </Typography>
                </a>
              </Popconfirm>
            </StyledTitleContaier>
          )}
        </>
      );
    },
  },
];
