import dayjs from "dayjs";
import { MdDeleteForever } from "react-icons/md";
import {
  NAME_LABEL,
  SURNAME_LABEL,
  EMAILS_LABEL,
  PROVIDER_LABEL,
  REGISTRATION_DATE_LABEL,
  ROLE_LABEL,
} from "./dictionary";
import { LanguageEnum } from "../types/appTypes";
import { FORMAT_DATE_HOURS } from "./AppConstants";

export const UserTableColumns = (
  handleDelete: (id: string) => void,
  language: LanguageEnum,
) => [
  {
    title: NAME_LABEL[language],
    dataIndex: "name",
    key: "name",
  },
  {
    title: SURNAME_LABEL[language],
    dataIndex: "surname",
    key: "surname",
  },
  {
    title: EMAILS_LABEL[language],
    dataIndex: "emails",
    key: "emails",
    render: (emails: string[]) =>
      emails.map((email, index) => <p key={`${email}-${index}`}>{email}</p>),
  },
  {
    title: PROVIDER_LABEL[language],
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: REGISTRATION_DATE_LABEL[language],
    dataIndex: "createdAt",
    key: "createdAt",
    render: (text: string) => <p>{dayjs(text).format(FORMAT_DATE_HOURS)}</p>,
  },
  {
    title: ROLE_LABEL[language],
    dataIndex: "role",
    key: "role",
  },
  {
    title: "",
    dataIndex: "_id",
    key: "_id",
    render: (id: string) => {
      return (
        <div className="table-action-icons">
          <MdDeleteForever
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(id);
            }}
          />
        </div>
      );
    },
  },
];
