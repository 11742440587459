import React, { useState, useEffect } from "react";
import { Users } from "../../../types/apiTypes";
import { Table } from "antd";
import { UserTableColumns } from "../../../constants/UserTablesConstants";
import { useApi } from "../../../hook/useApi";
import { deleteUser } from "../../../api/axiosInstance";
import { CustomModal } from "../../CustomModal/CustomModal";
import { useAppSelector } from "../../../hook/Store";

export const UsersTable = () => {
  const {
    response: initialResponse,
    isLoading,
    error,
  } = useApi<Users[]>("/api/users");
  const [response, setResponse] = useState<Users[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const { language } = useAppSelector((state) => state.user);

  useEffect(() => {
    initialResponse && setResponse(initialResponse);
  }, [initialResponse]);

  const onDeleteFunc = async () => {
    if (!selectedUserId) return;
    try {
      await deleteUser(selectedUserId);
      alert("Utente cancellato con successo");
      setResponse((prevResponse) =>
        prevResponse.filter((user) => user._id !== selectedUserId),
      );
      setOpenModal(false);
    } catch (error) {
      console.error("Errore durante la cancellazione dell'utente:", error);
      alert("Si è verificato un errore durante la cancellazione dell'utente");
    }
  };

  const handleDelete = (id: string) => {
    setSelectedUserId(id);
    setOpenModal(true);
  };

  return (
    <div>
      <CustomModal
        title="cancella utente"
        onOk={onDeleteFunc}
        onCancel={() => setOpenModal(false)}
        open={openModal}
        showFooter
      >
        vuoi cancellare utente?
      </CustomModal>
      <h1>Utenti registrati</h1>
      <div>
        {response && (
          <Table<Users>
            columns={UserTableColumns(handleDelete, language)}
            dataSource={response.map((user) => ({
              ...user,
              key: user._id,
            }))}
          />
        )}
        {isLoading && <div>is loading...</div>}
        {error && <div>Errore nel caricamento degli utenti: {error}</div>}
      </div>
    </div>
  );
};
