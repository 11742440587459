import React from "react";
import { StyledHeader } from "./Header.styled";
import { useAppSelector } from "../../hook/Store";
import { PopoverComponent } from "../PopoverComponent/PopoverComponent";
import { Avatar } from "../Avatar/Avatar";
import { UserPanel } from "../UserPanel/UserPanel";
import { FaRegUser } from "react-icons/fa";
import { DimensionsEnum, IconSizeEnum } from "../../types/appTypes";
import { IconManager } from "../IconManager/IconManager";
import { RiMenu2Fill } from "react-icons/ri";
import { RiMenu3Fill } from "react-icons/ri";
import { chooseFilterOption } from "../../constants/AppConstants";
import { FilterButtons } from "../FIlterButtons/FIlterButtons";
import { useDispatch } from "react-redux";
import { setChooseFilter } from "../../actions";
import { useDimensions } from "../../hook/useDimensions";
import { theme } from "../../constants/StyledComponents";
import { FilterSelect } from "../FilterSelect/FilterSelect";

type HeaderProps = {
  showSidebar: boolean;
  setShowSidebar: (show: boolean) => void;
  isChoose?: boolean;
};

export const Header = ({
  showSidebar,
  setShowSidebar,
  isChoose,
}: HeaderProps) => {
  const { userLogged, selectedChooseFilter } = useAppSelector(
    (state) => state.user,
  );
  const dispatch = useDispatch();
  const { width } = useDimensions();
  const isMobile = width <= theme.breakpoints.MD;
  const nameLetter =
    userLogged && userLogged.name
      ? userLogged.name.charAt(0).toUpperCase()
      : "";
  const surnameLetter =
    userLogged && userLogged.surname
      ? userLogged.surname.charAt(0).toUpperCase()
      : "";
  const initials = `${nameLetter}${surnameLetter}`;

  return (
    <StyledHeader>
      {!isChoose && (
        <IconManager
          onClick={() => setShowSidebar(!showSidebar)}
          size={IconSizeEnum.XSMALL}
        >
          {showSidebar ? <RiMenu2Fill /> : <RiMenu3Fill />}
        </IconManager>
      )}
      {isChoose && isMobile ? (
        <FilterSelect
          options={chooseFilterOption}
          selectedOption={selectedChooseFilter}
          setSelectedOption={(el) => dispatch(setChooseFilter(el))}
        />
      ) : (
        isChoose && (
          <FilterButtons
            selectedOption={selectedChooseFilter}
            setSelectedOption={(el) => dispatch(setChooseFilter(el))}
            options={chooseFilterOption}
          />
        )
      )}
      <PopoverComponent content={<UserPanel />}>
        <>
          <Avatar
            variant={DimensionsEnum.SMALL}
            icon={<FaRegUser />}
            initials={initials || ""}
          />
        </>
      </PopoverComponent>
    </StyledHeader>
  );
};
