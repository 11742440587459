import { IconManager } from "../components/IconManager/IconManager";
import {
  IconSizeEnum,
  LanguageEnum,
  PageNames,
  RolesEnum,
  SidebarItems,
} from "../types/appTypes";
import { MdDashboardCustomize } from "react-icons/md";
import { DASHBOARD_LABEL, CLIENTS_LABEL, PROJECTS_LABEL } from "./dictionary";
import { GrUserAdd } from "react-icons/gr";
import { GrProjects } from "react-icons/gr";

export const SIDEBAR_MENU_ITEMS = (
  language: LanguageEnum,
  role: RolesEnum,
): SidebarItems[] => [
  ...[
    {
      icon: (
        <IconManager size={IconSizeEnum.XSMALL}>
          <MdDashboardCustomize />
        </IconManager>
      ),
      label: DASHBOARD_LABEL[language],
      link: PageNames.DASHBOARD,
    },
  ],
  ...(role === RolesEnum.FREELANCE || role === RolesEnum.ADMIN
    ? [
        {
          icon: (
            <IconManager size={IconSizeEnum.XSMALL}>
              <GrUserAdd />
            </IconManager>
          ),
          label: CLIENTS_LABEL[language],
          link: PageNames.CLIENTS,
        },
      ]
    : []),
  ...(role === RolesEnum.FREELANCE
    ? [
        {
          icon: (
            <IconManager size={IconSizeEnum.XSMALL}>
              <GrProjects />
            </IconManager>
          ),
          label: PROJECTS_LABEL[language],
          link: PageNames.PROJECTS,
        },
      ]
    : []),
];
