import styled, { css, DefaultTheme } from "styled-components";
import { ButtonSizes, ButtonVariants } from "./Button";

type StyledButtonProps = {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  disabled?: boolean;
  color?: string;
  $fullwidth?: boolean;
};

/* variants */
const variantStyles = (
  theme: DefaultTheme,
  variant = ButtonVariants.PRIMARY,
  disabled = false,
  color = "black",
) =>
  ({
    primary: css`
      border: none;
      background-color: ${disabled ? theme.colors.grey_4 : theme.colors.purple};
      color: ${disabled ? theme.colors.grey_2 : color};
      pointer-events: ${disabled ? "none" : "all"};
      border-radius: ${theme.variables.border_radius_1};
      &:hover {
        background-color: ${theme.colors.purple_hover};
      }
      & svg path {
        stroke: ${disabled ? theme.colors.grey_2 : color};
        stroke-width: ${theme.variables.sw_3}!important;
      }
    `,
    secondary: css`
      background-color: white;
      color: ${disabled ? theme.colors.grey_2 : theme.colors.black};
      pointer-events: ${disabled ? "none" : "all"};
      border: 1.5px solid ${disabled ? theme.colors.grey_4 : theme.colors.black};
      border-radius: ${theme.variables.border_radius_1};
      &:hover {
        background-color: ${theme.colors.page_background};
      }
      & svg path {
        stroke: ${disabled ? theme.colors.grey_2 : theme.colors.black};
        stroke-width: ${theme.variables.sw_3}!important;
      }
    `,
  })[variant];

const sizeStyles = (theme: DefaultTheme, size = ButtonSizes.REGULAR) =>
  ({
    small: css`
      padding: 7px 10px;
      height: ${theme.variables.height_1};
    `,
    regular: css`
      padding: 10px 15px;
      height: ${theme.variables.height_3};
    `,
  })[size];

const StyledButton = styled.button<StyledButtonProps>`
  ${({ theme, variant, disabled }) => variantStyles(theme, variant, disabled)}
  ${({ theme, size }) => sizeStyles(theme, size)}
  cursor: pointer;
  width: ${({ $fullwidth }) => ($fullwidth ? "100%" : "fit-content")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  appearance: none;
  text-align: center;
  justify-content: center;

  & svg {
    margin-right: 8px;
  }
`;

export { StyledButton };
