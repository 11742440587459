import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Users } from "../types/apiTypes";
import { LanguageEnum } from "../types/appTypes";
import { chooseFilterOption, OptionItem } from "../constants/AppConstants";

export interface InitialStateProps {
  userLogged: false | Users;
  language: LanguageEnum;
  selectedChooseFilter: OptionItem;
}

const initialState = {
  userLogged: false,
  language: LanguageEnum.IT,
  selectedChooseFilter: chooseFilterOption[0],
} as InitialStateProps;

export const fetchUser = createSlice({
  name: "userLogged",
  initialState,
  reducers: {
    getUser: (
      state: InitialStateProps,
      action: PayloadAction<Users | false>,
    ) => {
      state.userLogged = action.payload || false;
    },
    setLanguage: (
      state: InitialStateProps,
      action: PayloadAction<LanguageEnum>,
    ) => {
      state.language = action.payload;
    },
    setChooseFilter: (
      state: InitialStateProps,
      action: PayloadAction<OptionItem>,
    ) => {
      state.selectedChooseFilter = action.payload;
    },
  },
});

export const { getUser, setLanguage, setChooseFilter } = fetchUser.actions;
export default fetchUser.reducer;
