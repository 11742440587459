import React, { useState } from "react";
import { StyledChoosePage, StyledNoData } from "./ChoosePage.styled";
import { ApprovalEnum } from "../../../types/appTypes";
import dayjs from "dayjs";
import { FORMAT_DATE } from "../../../constants/AppConstants";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ChooseSwiper } from "./ChooseSwiper/ChooseSwiper";
import { NOTHING_TO_DISPLAY_LABEL } from "../../../constants/dictionary";
import { useAppSelector } from "../../../hook/Store";
import { theme } from "../../../constants/StyledComponents";
import { useDimensions } from "../../../hook/useDimensions";
import { ChooseCardList } from "./ChooseCardList/ChooseCardList";
dayjs.extend(customParseFormat);

const data = [
  {
    id: "1",
    title: "Andiamo a comandare",
    date: "22/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "2",
    title: "Andiamo a mangiare asd asd asd asd asd asd asd ",
    date: "15/10/2024",
    text: "testo della campagna asd asd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asd asd asd sd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asd sd asd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asd asd asd sd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd asd asd asd asd asd asd as dasd asd as dasd asd asd asd asd asd asd asd asd asd asdsd asd as",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "3",
    title: "Andiamo a cagare",
    date: "30/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "4",
    title: "Andiamo a rubbbare",
    date: "01/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "5",
    title: "Andiamo a dormire",
    date: "07/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "6",
    title: "Andiamo a comandare",
    date: "22/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "7",
    title: "Andiamo a mangiare",
    date: "15/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "8",
    title: "Andiamo a cagare",
    date: "30/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "9",
    title: "Andiamo a rubbbare",
    date: "01/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
  {
    id: "10",
    title: "Andiamo a dormire",
    date: "07/10/2024",
    text: "testo della campagna",
    state: ApprovalEnum.TOAPPROVE,
  },
];

export const ChoosePage = () => {
  const { language, selectedChooseFilter } = useAppSelector(
    (state) => state.user,
  );
  const [dati, setDati] = useState(data);
  const { width } = useDimensions();
  const isMobile = width <= theme.breakpoints.MD;

  const handleApprove = (id: string) => {
    const updatedData = dati.map((item) => {
      if (item.id === id) {
        return { ...item, state: ApprovalEnum.APPROVED };
      }
      return item;
    });
    console.log(updatedData);
    setDati(updatedData);
  };

  const sortedAndFilteredData = dati
    .sort((a, b) => {
      const dateA = dayjs(a.date, FORMAT_DATE);
      const dateB = dayjs(b.date, FORMAT_DATE);
      return dateA.isBefore(dateB) ? -1 : 1;
    })
    .filter((el) =>
      selectedChooseFilter.value === ""
        ? el
        : el.state === selectedChooseFilter.value,
    );

  return (
    <StyledChoosePage>
      {sortedAndFilteredData.length > 0 ? (
        isMobile ? (
          <ChooseSwiper
            data={sortedAndFilteredData}
            handleApprove={handleApprove}
          />
        ) : (
          <ChooseCardList
            data={sortedAndFilteredData}
            handleApprove={handleApprove}
          />
        )
      ) : (
        <StyledNoData>{NOTHING_TO_DISPLAY_LABEL[language]}</StyledNoData>
      )}
    </StyledChoosePage>
  );
};
