import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FAQS_LABEL, PRIVACY_POLICY_LABEL } from "./dictionary";
import { Label, LanguageEnum } from "../types/appTypes";
import italyIcon from "..//assets/italy-flag-icon.png";
import englandIcon from "..//assets/england-flag-icon.png";

export const userPanelLinks: {
  label: Label;
  link: string;
  icon: React.ReactNode;
}[] = [
  {
    label: FAQS_LABEL,
    link: "/faq",
    icon: <FaQuestionCircle />,
  },
  {
    label: PRIVACY_POLICY_LABEL,
    link: "/privacy-policy",
    icon: <MdOutlinePrivacyTip />,
  },
];

export const languageSelector = [
  {
    lang: LanguageEnum.IT,
    icon: italyIcon,
  },
  {
    lang: LanguageEnum.EN,
    icon: englandIcon,
  },
];
