import React, { useContext, useEffect, useRef, useState } from "react";
import type { InputRef, GetRef } from "antd";
import { Form, Input } from "antd";
import { EditableCellProps, ModulesEnum } from "../../../types/appTypes";
import { Chips } from "../../../components/Chips/Chips";
import { modulesOptions } from "../../../constants/BuyTableConstants";
import { useAppSelector } from "../../../hook/Store";
import { SELECT_MODULE_LABEL } from "../../../constants/dictionary";

type FormInstance<T> = GetRef<typeof Form<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const EditableContext = React.createContext<FormInstance<any> | null>(
  null,
);

export const EditableCell: React.FC<
  React.PropsWithChildren<EditableCellProps>
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const { language } = useAppSelector((state) => state.user);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  const [previousModules, setPreviousModules] = useState<ModulesEnum[]>(
    record && record.modules,
  );

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  const onModulesChange = (selectedModules: ModulesEnum[]) => {
    const basePrice = record.price || 0;

    const addedModules = selectedModules.filter(
      (module) => !previousModules.includes(module),
    );
    const removedModules = previousModules.filter(
      (module) => !selectedModules.includes(module),
    );

    const addedPrice = addedModules.reduce((total, module) => {
      const option = modulesOptions.find((opt) => opt.value === module);
      return total + (option ? option.price : 0);
    }, 0);

    const removedPrice = removedModules.reduce((total, module) => {
      const option = modulesOptions.find((opt) => opt.value === module);
      return total + (option ? option.price : 0);
    }, 0);

    const finalPrice = basePrice + addedPrice - removedPrice;

    setPreviousModules(selectedModules);

    handleSave({
      ...record,
      modules: selectedModules,
      price: finalPrice,
    });
  };

  let childNode = children;
  if (editable) {
    if (dataIndex === "modules") {
      childNode = (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          initialValue={record.modules}
        >
          <Chips<ModulesEnum>
            value={record.modules}
            onChange={onModulesChange}
            options={modulesOptions}
            placeholder={SELECT_MODULE_LABEL[language]}
          />
        </Form.Item>
      );
    } else {
      childNode = editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[{ required: true, message: `${title} is required.` }]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingInlineEnd: 24 }}
          onClick={toggleEdit}
        >
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};
