import styled from "styled-components";
import { theme } from "../../../constants/StyledComponents";

type StyledDashboardViewProps = {
  $showSidebar: boolean;
};

export const StyledDashboardView = styled.div<StyledDashboardViewProps>`
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: ${theme.colors.page_background};

  & .right-content {
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.page_background};
    width: ${({ $showSidebar, theme }) =>
      $showSidebar
        ? `calc(100% - ${theme.variables.sidebar_big_width})`
        : `calc(100% - ${theme.variables.sidebar_hide_width})`};
    min-width: ${({ $showSidebar, theme }) =>
      $showSidebar
        ? `calc(100% - ${theme.variables.sidebar_big_width})`
        : `calc(100% - ${theme.variables.sidebar_hide_width})`};
  }
  & .page-container {
    background-color: ${theme.colors.page_background};
    height: 100%;
    overflow: auto;
    padding: 0 32px 12px;
  }
  .sidebar-overlayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.11);
    z-index: 98;
  }
  @media (max-width: ${theme.breakpoints.MD}px) {
    & .right-content {
      width: 100%;
      min-width: 100%;
    }
    & .page-container {
      padding: 0 15px 12px;
    }
  }
`;
