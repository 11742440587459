import styled, { css, DefaultTheme } from "styled-components";
import { DirectionEnum } from "../../types/appTypes";
import { theme } from "../../constants/StyledComponents";

type StyledAvatarNameProps = {
  direction: DirectionEnum;
};

/* variants */

const variantStyles = (theme: DefaultTheme, direction: DirectionEnum) =>
  ({
    vertical: css`
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & .avatar-name-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
      }
    `,
    horizontal: css`
      flex-direction: row;
      align-items: center;
      & .avatar-name-text {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
      }
    `,
  })[direction];

const StyledAvatarName = styled.div<StyledAvatarNameProps>`
  ${({ theme, direction }) => variantStyles(theme, direction)}
  display: flex;
  & svg {
    width: 18px;
    height: 18px;
    stroke: ${theme.colors.purple};
  }
`;

export { StyledAvatarName };
