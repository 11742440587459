import { ApprovalEnum, Label } from "../types/appTypes";
import {
  ALL_LABEL,
  APPROVED_LABEL,
  NOT_APPROVED_LABEL,
  TO_APPROVE_LABEL,
} from "./dictionary";

export const FORMAT_DATE_HOURS = "DD/MM/YYYY HH:mm";
export const FORMAT_DATE = "DD/MM/YYYY";

export type OptionItem = {
  value: string;
  label: Label;
};
export enum ChooseFilterEnum {
  ALL = "",
  APPROVED = ApprovalEnum.APPROVED,
  NOT_APPROVED = ApprovalEnum.NOTAPPROVED,
  TO_APPROVE = ApprovalEnum.TOAPPROVE,
}
export const chooseFilterOption: OptionItem[] = [
  { value: ChooseFilterEnum.ALL, label: ALL_LABEL },
  { value: ChooseFilterEnum.APPROVED, label: APPROVED_LABEL },
  { value: ChooseFilterEnum.NOT_APPROVED, label: NOT_APPROVED_LABEL },
  { value: ChooseFilterEnum.TO_APPROVE, label: TO_APPROVE_LABEL },
];
