import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledSidebarItemsCreator } from "./SidebarItemsCreator.styled";
import { SidebarItems } from "../../types/appTypes";
import { useDimensions } from "../../hook/useDimensions";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../Typography/Typography";

type SidebarItemsCreatorProps = {
  items: SidebarItems[];
  showSidebar: boolean;
  closeSidebar: () => void;
};

export const SidebarItemsCreator = ({
  items,
  showSidebar,
  closeSidebar,
}: SidebarItemsCreatorProps) => {
  const navigate = useNavigate();

  const pageActive = window.location.pathname.replace("/", "");
  const { width } = useDimensions();

  return (
    <StyledSidebarItemsCreator $showSidebar={showSidebar}>
      <>
        {items.map((item, idx) => {
          return (
            <React.Fragment key={`${idx}${item.label}`}>
              <div
                className={`item-sidebar ${
                  typeof item.link === "string" &&
                  (item.link.includes(pageActive) ||
                    (item.otherSelected &&
                      item.otherSelected.includes(pageActive))) &&
                  "active"
                }`}
                onClick={() => {
                  if (typeof item.link === "string") {
                    navigate(item.link);
                  } else {
                    item.link();
                  }

                  width <= 768 && closeSidebar();
                }}
              >
                {item.icon}
                {showSidebar && (
                  <Typography
                    fontSize={FontSizeEnum.SM}
                    fontWeight={FontWeightEnum.MEDIUM}
                  >
                    {item.label}
                  </Typography>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </>
    </StyledSidebarItemsCreator>
  );
};
