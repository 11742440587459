import { Label } from "../types/appTypes";

export const EMPTY_STRING = "-";
export const SIGNIN_SIGNUP = "Sign in or Sign up with:";
export const NAME_LABEL: Label = {
  en: "Name",
  it: "Nome",
};
export const SURNAME_LABEL: Label = {
  en: "Surname",
  it: "Cognome",
};
export const EMAILS_LABEL: Label = {
  en: "Emails",
  it: "Email",
};
export const PROVIDER_LABEL: Label = {
  en: "Provider",
  it: "Provider",
};
export const REGISTRATION_DATE_LABEL: Label = {
  en: "Registration Date",
  it: "Data Registrazione",
};
export const ROLE_LABEL: Label = {
  en: "Role",
  it: "Ruolo",
};
export const FAQS_LABEL: Label = {
  en: "FAQs",
  it: "FAQs",
};
export const PRIVACY_POLICY_LABEL: Label = {
  en: "Privacy Policy",
  it: "Privacy Policy",
};
export const LOGOUT_LABEL: Label = {
  en: "Logout",
  it: "Logout",
};
export const DASHBOARD_LABEL: Label = {
  en: "Dashboard",
  it: "Dashboard",
};
export const NEW_CLIENT_LABEL: Label = {
  en: "New client",
  it: "Nuovo cliente",
};
export const CLIENTS_LABEL: Label = {
  en: "Clients",
  it: "Clienti",
};
export const GOOGLE_LABEL: Label = {
  en: "Google",
  it: "Google",
};
export const CHANGE_LANGUAGE_LABEL: Label = {
  en: "Change language",
  it: "Cambia lingua",
};

export const APPROVE_LABEL: Label = {
  en: "Approve",
  it: "Approva",
};
export const NOT_APPROVE_LABEL: Label = {
  en: "Not Approve",
  it: "Non Approvare",
};
export const APPROVED_LABEL: Label = {
  en: "Approved",
  it: "Approvati",
};
export const NOT_APPROVED_LABEL: Label = {
  en: "Not Approved",
  it: "Non Approvati",
};
export const TO_APPROVE_LABEL: Label = {
  en: "To Approve",
  it: "Da Approvare",
};
export const PUBLICATION_DATE_LABEL: Label = {
  en: "Publication date: ",
  it: "Data Pubblicazione: ",
};
export const NOTHING_TO_DISPLAY_LABEL: Label = {
  en: "Nothing to display",
  it: "Niente da visualizzare",
};
export const ALL_LABEL: Label = {
  en: "All",
  it: "Tutti",
};
export const NO_PROJECTS_PRESENT_LABEL: Label = {
  en: "No projects present",
  it: "Nessun progetto presente",
};
export const NO_CLIENTS_PRESENT_LABEL: Label = {
  en: "No clients present",
  it: "Nessun cliente presente",
};
export const ADD_LABEL: Label = {
  en: "Add",
  it: "Aggiungi",
};
export const REGISTERED_CUSTOMERS_LABEL: Label = {
  en: "Registered customers",
  it: "Clienti registrati",
};
export const PROJECT_NAME_LABEL: Label = {
  en: "Project Name",
  it: "Nome Progetto",
};
export const MODULES_LABEL: Label = {
  en: "Modules",
  it: "Moduli",
};
export const PRICE_LABEL: Label = {
  en: "Price",
  it: "Prezzo",
};
export const PROJECT_LABEL: Label = {
  en: "Project",
  it: "Progetto",
};
export const PROJECTS_LABEL: Label = {
  en: "Projects",
  it: "Progetti",
};
export const TOTAL_LABEL: Label = {
  en: "Total: ",
  it: "Totale: ",
};
export const SAVE_LABEL: Label = {
  en: "Save",
  it: "Salva",
};
export const EDIT_LABEL: Label = {
  en: "Edit",
  it: "Modifica",
};
export const EDITORIAL_CALENDAR_LABEL: Label = {
  en: "Editorial Calendar",
  it: "Calendario Editoriale",
};
export const SOCIAL_LABEL: Label = {
  en: "Social",
  it: "Social",
};
export const STATIC_POSTS_LABEL: Label = {
  en: "Static Posts",
  it: "Post Statici",
};
export const VIDEO_POSTS_LABEL: Label = {
  en: "Video Posts",
  it: "Post Video",
};
export const MIN_POSTS_LABEL: Label = {
  en: "Minimal Posts",
  it: "Post Minimi",
};
export const SELECT_CUSTOMER_LABEL: Label = {
  en: "Select Customer",
  it: "Seleziona Cliente",
};
export const SELECT_CALENDAR_LABEL: Label = {
  en: "Select Calendar",
  it: "Seleziona Calendario",
};
export const SELECT_SOCIAL_LABEL: Label = {
  en: "Select Social",
  it: "Seleziona Social",
};
export const SELECT_MODULE_LABEL: Label = {
  en: "Select Module",
  it: "Seleziona Modulo",
};
export const DELETE_LABEL: Label = {
  en: "Delete",
  it: "Elimina",
};
export const CANCEL_LABEL: Label = {
  en: "Cancel",
  it: "Annulla",
};
export const CUSTOMER_LABEL: Label = {
  en: "Customer",
  it: "Cliente",
};
export const DAILY_LABEL: Label = {
  en: "Daily",
  it: "Giornaliero",
};

export const WEEKLY_LABEL: Label = {
  en: "Weekly",
  it: "Settimanale",
};

export const BIWEEKLY_LABEL: Label = {
  en: "Biweekly",
  it: "Bisettimanale",
};

export const BIMONTHLY_LABEL: Label = {
  en: "Bimonthly",
  it: "Bimestrale",
};

export const QUARTERLY_LABEL: Label = {
  en: "Quarterly",
  it: "Trimestrale",
};

export const QUADRIMESTRAL_LABEL: Label = {
  en: "Quadrimestral",
  it: "Quadrimestrale",
};

export const SEMIANNUAL_LABEL: Label = {
  en: "Semiannual",
  it: "Semestrale",
};

export const ANNUAL_LABEL: Label = {
  en: "Annual",
  it: "Annuale",
};

export const BIENNIAL_LABEL: Label = {
  en: "Biennial",
  it: "Biennale",
};

export const TRIENNIAL_LABEL: Label = {
  en: "Triennial",
  it: "Triennale",
};
export const ERROR_NO_CLIENT_TO_CONNECT_LABEL: Label = {
  en: "You haven't invited any customers yet, go to the Customers page and invite them",
  it: "Non hai ancora invitato nessun cliente, vai alla pagina Clienti e invitali",
};
export const BUY_LABEL: Label = {
  en: "Buy",
  it: "Acquista",
};
export const BUY_PROJECTS_LABEL: Label = {
  en: "Buy Projects",
  it: "Acquista Progetti",
};
export const ADD_PROJECT_LABEL: Label = {
  en: "Add Project",
  it: "Aggiungi Progetto",
};
