import React, { useState } from "react";
import type { TableProps } from "antd";
import { Form, Table } from "antd";
import { BuyDataType } from "../../../types/appTypes";
import { useAppSelector } from "../../../hook/Store";
import { defaultBuyColumns } from "../../../constants/BuyTableConstants";
import { EditableCell, EditableContext } from "./EditableCell";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_PROJECT_LABEL,
  PROJECT_LABEL,
  TOTAL_LABEL,
} from "../../../constants/dictionary";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../../components/Typography/Typography";
import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from "../../../components/Button/Button";
import { IoMdAdd } from "react-icons/io";
import { StyledBuyTable } from "./BuyTable.styled";

interface EditableRowProps {
  index: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export type BuyColumnTypes = Exclude<
  TableProps<BuyDataType>["columns"],
  undefined
>;
type BuyTableProps = {
  dataSource: BuyDataType[];
  setDataSource: React.Dispatch<React.SetStateAction<BuyDataType[]>>;
};
export const BuyTable = ({ dataSource, setDataSource }: BuyTableProps) => {
  const { language } = useAppSelector((state) => state.user);

  const [count, setCount] = useState(2);

  const handleDelete = (id: string) => {
    const newData = dataSource.filter((item) => item.id !== id);
    setDataSource(newData);
  };

  const handleAdd = () => {
    const newData: BuyDataType = {
      id: uuidv4(),
      nameProject: `${PROJECT_LABEL[language]} ${count}`,
      modules: [],
      price: 25,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = (row: BuyDataType) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultBuyColumns(language, dataSource, handleDelete).map(
    (col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: BuyDataType) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
        }),
      };
    },
  );
  const totalPrice = dataSource.reduce((total, project) => {
    return total + project.price;
  }, 0);
  return (
    <StyledBuyTable>
      <Button
        variant={ButtonVariants.SECONDARY}
        size={ButtonSizes.SMALL}
        label={ADD_PROJECT_LABEL}
        onClick={handleAdd}
        icon={<IoMdAdd />}
        color="black"
        fontSize={FontSizeEnum.SM}
      />
      <Table<BuyDataType>
        footer={() => (
          <Typography
            fontWeight={FontWeightEnum.REGULAR}
            fontSize={FontSizeEnum.SM}
          >
            {TOTAL_LABEL[language]}
            <Typography
              fontWeight={FontWeightEnum.MEDIUM}
              fontSize={FontSizeEnum.MD}
            >
              {`${totalPrice}€`}
            </Typography>
          </Typography>
        )}
        components={components}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={dataSource.map((item) => ({ ...item, key: item.id }))}
        columns={columns as BuyColumnTypes}
      />
    </StyledBuyTable>
  );
};
