import React from "react";
import { StyledUserPanel } from "./UserPanel.styled";
import { theme } from "../../constants/StyledComponents";
import { useAppDispatch, useAppSelector } from "../../hook/Store";
import { useNavigate } from "react-router-dom";
import {
  CHANGE_LANGUAGE_LABEL,
  LOGOUT_LABEL,
} from "../../constants/dictionary";
import {
  DirectionEnum,
  DimensionsEnum,
  LinkSizeEnum,
} from "../../types/appTypes";
import { AvatarName } from "../AvatarName/AvatarName";
import { Avatar } from "../Avatar/Avatar";
import { FaRegUser } from "react-icons/fa";
import { Divider } from "../Divider/Divider";
import { Link } from "../Link/Link";
import { MdLogout } from "react-icons/md";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../Typography/Typography";
import {
  languageSelector,
  userPanelLinks,
} from "../../constants/UserPanelConstants";
import { setLanguage } from "../../actions";
export const UserPanel = () => {
  const { userLogged, language } = useAppSelector((state) => state.user);
  const name = userLogged && userLogged.name;
  const surname = userLogged && userLogged.surname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const nameLetter =
    userLogged && userLogged.name
      ? userLogged.name.charAt(0).toUpperCase()
      : "";
  const surnameLetter =
    userLogged && userLogged.surname
      ? userLogged.surname.charAt(0).toUpperCase()
      : "";
  const initials = `${nameLetter}${surnameLetter}`;

  return (
    <StyledUserPanel>
      {userLogged && (
        <AvatarName direction={DirectionEnum.VERTICAL}>
          <Avatar
            initials={initials || ""}
            variant={DimensionsEnum.BIG}
            icon={<FaRegUser />}
          />
          <div className="avatar-name-text">
            <Typography
              fontWeight={FontWeightEnum.MEDIUM}
              fontSize={FontSizeEnum.SM}
            >
              {`${name} ${surname}`}
            </Typography>
          </div>
        </AvatarName>
      )}
      <Divider />
      {userPanelLinks.map((item, index) => {
        const handleClick = () => {
          navigate(item.link);
        };
        return (
          <div className="link-list" key={`${item.label}${index}`}>
            <Link
              key={`${item.label[language]}${index}`}
              onClick={handleClick}
              underline
              icon={item.icon}
              size={LinkSizeEnum.SMALL}
            >
              <Typography
                fontWeight={FontWeightEnum.REGULAR}
                fontSize={FontSizeEnum.SM}
              >
                {item.label[language]}
              </Typography>
            </Link>
          </div>
        );
      })}
      <Divider />
      <div className="language-selector">
        <Typography
          fontWeight={FontWeightEnum.REGULAR}
          fontSize={FontSizeEnum.SM}
        >
          {CHANGE_LANGUAGE_LABEL[language]}
        </Typography>
        <div>
          {languageSelector.map((flag, i) => (
            <img
              key={`${flag.lang}${i}`}
              src={flag.icon}
              alt={`${flag.lang} icon`}
              style={{ width: 24, height: 24 }}
              onClick={() => dispatch(setLanguage(flag.lang))}
            />
          ))}
        </div>
      </div>
      <Divider />
      <Link
        onClick={() =>
          (window.location.href = `${process.env.REACT_APP_BE_DOMAIN}/api/logout`)
        }
        color={theme.colors.purple}
        icon={<MdLogout />}
        size={LinkSizeEnum.SMALL}
      >
        <Typography
          fontWeight={FontWeightEnum.SEMIBOLD}
          fontSize={FontSizeEnum.MD}
        >
          {LOGOUT_LABEL[language]}
        </Typography>
      </Link>
    </StyledUserPanel>
  );
};
