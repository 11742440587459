import styled from "styled-components";

export const StyledLoginButton = styled.button`
  border-radius: 100px;
  width: 170px;
  height: 45px;
  border: 1px solid #757775;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  cursor: pointer;
`;
