import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { PrivateRoute } from "./components/PrivateRoute";
import { Login } from "./screens/Login/Login";
import { StyledApp } from "./App.styled";
import { PageNames } from "./types/appTypes";
import { Buy } from "./screens/Buy/Buy";
import { useAuth } from "./hook/useAuth";
import { RolesEnum } from "./types/appTypes";
import { DashboardView } from "./components/PageFormatter/DashboardView/DashboardView";
import { InvitedView } from "./components/PageFormatter/InvitedView/InvitedView";
import { Clients } from "./screens/Clients/Clients";
import { ChoosePage } from "./screens/User/ChoosePage/ChoosePage";
import { Projects } from "./screens/Projects/Projects";

export const App = () => {
  const { userLogged } = useAuth();

  if (userLogged) {
    if (
      userLogged.role === RolesEnum.FREELANCE ||
      userLogged.role === RolesEnum.ADMIN
    ) {
      if (window.location.pathname === "/") {
        return <Navigate to={PageNames.DASHBOARD} />;
      }
    } else if (userLogged.role === RolesEnum.USER) {
      if (window.location.pathname === "/") {
        return <Navigate to={PageNames.CHOOSE} />;
      }
    }
  }

  return (
    <StyledApp>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route element={<InvitedView />}>
            <Route path={PageNames.CHOOSE} element={<ChoosePage />} />
          </Route>
          <Route element={<DashboardView />}>
            <Route path={PageNames.DASHBOARD} element={<Dashboard />} />
            <Route path={PageNames.CLIENTS} element={<Clients />} />
            <Route path={PageNames.PROJECTS} element={<Projects />} />
            <Route path={PageNames.BUY} element={<Buy />} />
          </Route>
        </Route>
      </Routes>
    </StyledApp>
  );
};
