import React from "react";
import { UsersTable } from "../../components/Tables/UsersTable/UsersTable";

export const Dashboard = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
};
