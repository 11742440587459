import React, { useState } from "react";
import { StyledDashboardView } from "./DashboardView.styled";
import { Header } from "../../Header/Header";
import { Outlet } from "react-router";
import { Sidebar } from "../../Sidebar/Sidebar";
import { useDimensions } from "../../../hook/useDimensions";
import { theme } from "../../../constants/StyledComponents";

export const DashboardView = () => {
  const { width } = useDimensions();
  const isTablet = width <= theme.breakpoints.LG;
  const [showSidebar, setShowSidebar] = useState(!isTablet);
  return (
    <StyledDashboardView $showSidebar={showSidebar}>
      {showSidebar && width <= theme.breakpoints.MD && (
        <div
          className="sidebar-overlayer show-mobile"
          onClick={() => setShowSidebar(!showSidebar)}
        />
      )}
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <div className={`right-content ${showSidebar ? "open" : "close"}`}>
        <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <div className="page-container">
          <Outlet />
        </div>
      </div>
    </StyledDashboardView>
  );
};
