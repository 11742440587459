import React from "react";

import { StyledProjects } from "./Projects.styled";
import { ProjectsTable } from "../../components/Tables/ProjectsTable/ProjectsTable";

export const Projects = () => {
  return (
    <StyledProjects>
      <ProjectsTable />
    </StyledProjects>
  );
};
