import styled, { css, DefaultTheme } from "styled-components";

import { DimensionsEnum } from "../../types/appTypes";
import { theme } from "../../constants/StyledComponents";

type StyledAvatarProps = {
  $variant: DimensionsEnum;
};

const variantStyles = (theme: DefaultTheme, $variant: DimensionsEnum) =>
  ({
    small: css`
      height: ${theme.variables.height_1};
      width: ${theme.variables.height_1};
    `,
    big: css`
      height: ${theme.variables.height_4};
      width: ${theme.variables.height_4};
    `,
  })[$variant];

const StyledAvatar = styled.div<StyledAvatarProps>`
  ${({ theme, $variant }) => variantStyles(theme, $variant)}
  background-color: ${theme.colors.purple_3};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 100%;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  & .icon-avatar {
    box-shadow: 0px 2px 8px 0px rgba(74, 74, 79, 0.2);
    position: absolute;
    bottom: -5px;
    right: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG ? "-5px" : "-3px"};
    background-color: white;
    width: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG
        ? theme.variables.fs_22
        : theme.variables.fs_14};
    height: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG
        ? theme.variables.fs_22
        : theme.variables.fs_14};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
  & .icon-avatar svg {
    width: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG ? "16px" : "10px"};
    height: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG ? "16px" : "10px"};
    stroke-width: ${({ $variant }) =>
      $variant === DimensionsEnum.BIG
        ? theme.variables.sw_2
        : theme.variables.sw_1};
    stroke: ${theme.colors.purple};
  }
`;

export { StyledAvatar };
