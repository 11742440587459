import React from "react";
import { StyledDivider } from "./Divider.styled";

type DividerProps = {
  vertical?: boolean;
};

export const Divider = ({ vertical }: DividerProps) => {
  return <StyledDivider $vertical={vertical ? vertical : false} />;
};
