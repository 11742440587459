import React, { useEffect, useState } from "react";
import type { TableProps } from "antd";
import { Form, Input, InputNumber, Table } from "antd";
import { ProjectsData, Users } from "../../../types/apiTypes";
import {
  CalendarFrequencyEnum,
  PageNames,
  SocialPlatformEnum,
} from "../../../types/appTypes";
import {
  calendarFrequencyOptions,
  DEFAULT_PROJECTS_TABLE,
  ProjectsColumns,
  socialPlatformOptions,
} from "../../../constants/ProjectsTableConstants";
import { useAppSelector } from "../../../hook/Store";
import { CustomSelect } from "../../CustomSelect/CustomSelect";
import { Chips } from "../../Chips/Chips";
import { useApi } from "../../../hook/useApi";
import {
  ADD_LABEL,
  ERROR_NO_CLIENT_TO_CONNECT_LABEL,
  PROJECTS_LABEL,
  SELECT_CALENDAR_LABEL,
  SELECT_CUSTOMER_LABEL,
  SELECT_SOCIAL_LABEL,
} from "../../../constants/dictionary";
import { StyledProjectsTable } from "./ProjectsTable.styled";
import { Button, ButtonSizes, ButtonVariants } from "../../Button/Button";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { StyledTitleContaier } from "../ClientsTable/ClientsTable.styled";
import { apiClient } from "../../../api/axiosInstance";
import { NoDataInserted } from "../../NoDataInserted/NoDataInserted";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../Typography/Typography";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: "number" | "text";
  record: ProjectsData;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  record,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const ProjectsTable = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState<ProjectsData[]>([]);
  const [clients, setClients] = useState<Users[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const { language } = useAppSelector((state) => state.user);

  const {
    response: projectsList,
    isLoading: projectsLoading,
    error: projectsError,
  } = useApi<ProjectsData[]>("/api/projects");
  const { response: clientList, isLoading: clientsLoading } =
    useApi<Users[]>("/api/clients");

  useEffect(() => {
    if (projectsList) {
      setData(projectsList);
    }
  }, [projectsList]);
  console.log(data);
  useEffect(() => {
    clientList && setClients(clientList);
  }, [clientList]);

  const handleUpdateCalendarFrequency = (
    id: string,
    newFrequency: CalendarFrequencyEnum,
  ) => {
    setData((prevData) =>
      prevData.map((project) =>
        project.id === id
          ? { ...project, calendarFrequency: newFrequency }
          : project,
      ),
    );
  };

  const clientOptions = clients.map((client) => ({
    label: `${client.name} ${client.surname}`,
    value: client._id,
  }));

  const handleUpdateSocialPlatforms = (
    id: string,
    newPlatforms: SocialPlatformEnum[],
  ) => {
    setData((prevData) =>
      prevData.map((project) =>
        project.id === id ? { ...project, social: newPlatforms } : project,
      ),
    );
  };

  const isEditing = (record: ProjectsData) => record.id === editingKey;

  const edit = (record: Partial<ProjectsData> & { id: string }) => {
    form.setFieldsValue({ ...DEFAULT_PROJECTS_TABLE, ...record });
    console.log("record in edit", record);
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const handleUpdateClient = (projectId: string, clientId: string) => {
    const updatedData = data.map((project) =>
      project.id === projectId ? { ...project, client: clientId } : project,
    );

    setData(updatedData);
  };
  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as Partial<ProjectsData>;

      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        const updatedProject = { ...item, ...row };

        newData.splice(index, 1, updatedProject);
        setData(newData);

        console.log("Payload inviato all'API:", updatedProject);

        await apiClient.put(`/api/projects/update/${id}`, updatedProject);
        setEditingKey("");
      } else {
        newData.push(row as ProjectsData);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await apiClient.delete(`/api/projects/delete/${id}`);

      const newData = data.filter((item) => item.id !== id);
      setData(newData);
    } catch (error) {
      console.log("Delete Failed:", error);
    }
  };

  const mergedColumns: TableProps<ProjectsData>["columns"] = ProjectsColumns(
    isEditing,
    language,
    save,
    cancel,
    edit,
    editingKey !== "",
    handleDelete,
    handleUpdateCalendarFrequency,
    handleUpdateSocialPlatforms,
    handleUpdateClient,
    clientOptions,
  ).map((col) => {
    if (col.dataIndex === "calendarFrequency") {
      return {
        ...col,
        render: (_: string, record: ProjectsData) => (
          <CustomSelect
            disabled={!isEditing(record)}
            placeholder={SELECT_CALENDAR_LABEL[language]}
            value={record.calendarFrequency || undefined}
            onChange={(value) =>
              handleUpdateCalendarFrequency(
                record.id,
                value as CalendarFrequencyEnum,
              )
            }
            options={calendarFrequencyOptions(language)}
          />
        ),
      };
    }
    if (col.dataIndex === "social") {
      return {
        ...col,
        render: (_: string, record: ProjectsData) => (
          <Chips<SocialPlatformEnum>
            placeholder={SELECT_SOCIAL_LABEL[language]}
            disabled={!isEditing(record)}
            value={record.social}
            onChange={(value) => handleUpdateSocialPlatforms(record.id, value)}
            options={socialPlatformOptions}
          />
        ),
      };
    }
    if (col.dataIndex === "client") {
      return {
        ...col,
        render: (_: string, record: ProjectsData) => (
          <CustomSelect
            disabled={!isEditing(record)}
            placeholder={SELECT_CUSTOMER_LABEL[language]}
            options={clientOptions}
            value={record.client || undefined}
            onChange={(value) => handleUpdateClient(record.id, value)}
          />
        ),
      };
    }
    if (col.editable) {
      return {
        ...col,
        onCell: (record: ProjectsData) => ({
          record,
          inputType:
            col.dataIndex === "staticPost" ||
            col.dataIndex === "videoPost" ||
            col.dataIndex === "minPost"
              ? "number"
              : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    }
    return col;
  });

  return (
    <StyledProjectsTable>
      <StyledTitleContaier>
        <h1>{PROJECTS_LABEL[language]}</h1>
        {projectsList && projectsList.length > 0 && (
          <Button
            variant={ButtonVariants.SECONDARY}
            size={ButtonSizes.REGULAR}
            label={ADD_LABEL}
            onClick={() => navigate(PageNames.BUY)}
            icon={<IoMdAdd />}
            color="black"
          />
        )}
      </StyledTitleContaier>

      <>
        {clientList &&
          projectsList &&
          clientList.length > 0 &&
          projectsList.length > 0 && (
            <Form form={form} component={false}>
              <Table<ProjectsData>
                components={{
                  body: { cell: EditableCell },
                }}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{ onChange: cancel }}
              />
            </Form>
          )}
        {!projectsLoading && projectsList && projectsList.length === 0 && (
          <NoDataInserted onClick={() => navigate(PageNames.BUY)} />
        )}
        {!projectsLoading &&
          !clientsLoading &&
          clientList &&
          clientList.length === 0 && (
            <Typography
              fontWeight={FontWeightEnum.MEDIUM}
              fontSize={FontSizeEnum.SM}
            >
              {ERROR_NO_CLIENT_TO_CONNECT_LABEL[language]}
            </Typography>
          )}
        {projectsLoading && <div>is loading...</div>}
        {projectsError && (
          <div>Errore nel caricamento dei clienti: {projectsError}</div>
        )}
      </>
    </StyledProjectsTable>
  );
};
