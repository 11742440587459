import styled from "styled-components";

export const StyledChooseCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  & > div {
    width: min-content;
  }
  & > div > div {
    width: 325px;
    min-height: 237px;
    cursor: pointer;
  }
  & .ant-modal-content {
    padding: 47px;
  }
`;
