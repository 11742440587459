import React from "react";
import { Select } from "antd";
import { ModulesEnum, SocialPlatformEnum } from "../../types/appTypes";

type EnumType = ModulesEnum | SocialPlatformEnum;

type ChipsProps<T extends EnumType> = {
  value?: T[];
  onChange?: (value: T[]) => void;
  options: { label: T; value: T; price?: number }[];
  placeholder: string;
  disabled?: boolean;
};

export const Chips = <T extends EnumType>({
  value = [],
  onChange,
  options,
  placeholder,
  disabled,
}: ChipsProps<T>) => (
  <Select
    disabled={disabled}
    mode="multiple"
    allowClear
    placeholder={placeholder}
    value={value}
    onChange={(newValue) => onChange?.(newValue as T[])}
    options={options}
  />
);
