import React from "react";
import { Popover } from "antd";
import { StyledPopoverComponent } from "./PopoverComponent.styled";

type PopoverComponentProps = {
  children: React.ReactNode;
  content: React.ReactNode;
};

export const PopoverComponent = ({
  children,
  content,
}: PopoverComponentProps) => {
  return (
    <StyledPopoverComponent>
      <Popover content={content} trigger="click" placement="bottom">
        {children}
      </Popover>
    </StyledPopoverComponent>
  );
};
