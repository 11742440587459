import React from "react";
import { StyledButtons, StyledChooseContainer } from "./ChooseContainer.styled";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../../../components/Typography/Typography";
import {
  APPROVE_LABEL,
  NOT_APPROVE_LABEL,
} from "../../../../constants/dictionary";
import { useAppSelector } from "../../../../hook/Store";
import { ApprovalEnum } from "../../../../types/appTypes";

type ChooseContainerProps = {
  children: React.ReactNode;
  handleApprove?: (id: string) => void;
  id: string;
  showButtons?: boolean;
};

export const ChooseContainer = ({
  children,
  handleApprove,
  id,
  showButtons,
}: ChooseContainerProps) => {
  const { language, selectedChooseFilter } = useAppSelector(
    (state) => state.user,
  );

  const isApprovalFilter =
    selectedChooseFilter.value === ApprovalEnum.TOAPPROVE;
  return (
    <StyledChooseContainer>
      {children}
      {showButtons && handleApprove && isApprovalFilter && (
        <StyledButtons>
          <button onClick={() => handleApprove(id)}>
            <Typography
              fontWeight={FontWeightEnum.SEMIBOLD}
              fontSize={FontSizeEnum.LG}
            >
              {APPROVE_LABEL[language]}
            </Typography>
          </button>
          <button>
            <Typography
              fontWeight={FontWeightEnum.SEMIBOLD}
              fontSize={FontSizeEnum.LG}
            >
              {NOT_APPROVE_LABEL[language]}
            </Typography>
          </button>
        </StyledButtons>
      )}
    </StyledChooseContainer>
  );
};
