import React, { useState, useEffect } from "react";
import { Users } from "../../../types/apiTypes";
import { Table } from "antd";
import { UserTableColumns } from "../../../constants/UserTablesConstants";
import { useApi } from "../../../hook/useApi";
import { deleteUser } from "../../../api/axiosInstance";
import { CustomModal } from "../../CustomModal/CustomModal";
import { useAppSelector } from "../../../hook/Store";
import { NoDataInserted } from "../../NoDataInserted/NoDataInserted";
import {
  ADD_LABEL,
  REGISTERED_CUSTOMERS_LABEL,
} from "../../../constants/dictionary";
import { StyledClientsTable, StyledTitleContaier } from "./ClientsTable.styled";
import { Button, ButtonSizes, ButtonVariants } from "../../Button/Button";
import { IoMdAdd } from "react-icons/io";

type ClientsTableProps = {
  setAddModal: () => void;
};

export const ClientsTable = ({ setAddModal }: ClientsTableProps) => {
  const {
    response: initialResponse,
    isLoading,
    error,
  } = useApi<Users[]>("/api/clients");
  const [response, setResponse] = useState<Users[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const { language } = useAppSelector((state) => state.user);

  useEffect(() => {
    initialResponse && setResponse(initialResponse);
  }, [initialResponse]);

  const onDeleteFunc = async () => {
    if (!selectedUserId) return;
    try {
      await deleteUser(selectedUserId);
      alert("Utente cancellato con successo");
      setResponse((prevResponse) =>
        prevResponse.filter((user) => user._id !== selectedUserId),
      );
      setOpenModal(false);
    } catch (error) {
      console.error("Errore durante la cancellazione dell'utente:", error);
      alert("Si è verificato un errore durante la cancellazione dell'utente");
    }
  };

  const handleDelete = (id: string) => {
    setSelectedUserId(id);
    setOpenModal(true);
  };

  return (
    <StyledClientsTable>
      <CustomModal
        title="cancella utente"
        onOk={onDeleteFunc}
        onCancel={() => setOpenModal(false)}
        open={openModal}
        showFooter
      >
        vuoi cancellare cliente?
      </CustomModal>
      <StyledTitleContaier>
        <h1>{REGISTERED_CUSTOMERS_LABEL[language]}</h1>
        {response && response.length > 0 && (
          <Button
            variant={ButtonVariants.SECONDARY}
            size={ButtonSizes.REGULAR}
            label={ADD_LABEL}
            onClick={setAddModal}
            icon={<IoMdAdd />}
            color="black"
          />
        )}
      </StyledTitleContaier>

      <>
        {response && response.length > 0 && (
          <Table<Users>
            columns={UserTableColumns(handleDelete, language)}
            dataSource={response.map((user) => ({
              ...user,
              key: user._id,
            }))}
          />
        )}
        {!isLoading && response && response.length === 0 && (
          <NoDataInserted isClients onClick={setAddModal} />
        )}
        {isLoading && <div>is loading...</div>}
        {error && <div>Errore nel caricamento dei clienti: {error}</div>}
      </>
    </StyledClientsTable>
  );
};
