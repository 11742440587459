import React, { useEffect } from "react";
import { useAppSelector } from "../../hook/Store";
import { LoginButton } from "./LoginButton/LoginButton";
import { loginButtons } from "../../constants/LoginConstants";
import { LoginCard } from "./LoginCard/LoginCard";
import { useSearchParams } from "react-router-dom";

export const Login = () => {
  const { language } = useAppSelector((state) => state.user);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      localStorage.setItem("inviteToken", token);
    } else {
      localStorage.removeItem("inviteToken");
    }
  }, []);

  const handleGoogleLogin = () => {
    const token = localStorage.getItem("inviteToken");

    let googleAuthUrl = `${process.env.REACT_APP_BE_DOMAIN}/auth/google`;
    if (token) {
      googleAuthUrl += `?token=${token}`;
      localStorage.removeItem("inviteToken");
    }

    window.location.href = googleAuthUrl;
  };

  return (
    <LoginCard>
      {loginButtons(language).map((button) => (
        <LoginButton
          key={button.key}
          icon={button.icon}
          label={button.label}
          onCLick={button.key === "google" ? handleGoogleLogin : button.onclick}
        />
      ))}
    </LoginCard>
  );
};
