import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

type StyledDividerProps = {
  $vertical: boolean;
};

const StyledDivider = styled.div<StyledDividerProps>`
  background-color: ${theme.colors.purple_2};
  width: ${({ $vertical }) => ($vertical ? "1px" : "100%")};
  height: ${({ $vertical }) => ($vertical ? "100%" : "1px")};
  margin: ${({ $vertical }) => ($vertical ? "0px 20px" : "20px 0px")};
`;

export { StyledDivider };
