import styled from "styled-components";

export const StyledChooseCard = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: column;
  }
  & span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    max-height: 3em;
    line-height: 1.5em;
  }
  .ant-modal-body & span,
  .swiper & span {
    display: inline;
    -webkit-box-orient: initial;
    overflow: visible;
    -webkit-line-clamp: unset;
    text-overflow: initial;
    max-height: none;
    line-height: normal;
  }
  & > div > span:first-child {
    margin-bottom: 10px;
    display: block;
  }
  .ant-modal-body & > span:last-child,
  .swiper & > span:last-child {
    max-height: 275px;
    overflow-y: auto;
  }
`;
