import React from "react";

import { SyledTypography } from "./Typography.styled";

export enum FontWeightEnum {
  REGULAR = 500,
  MEDIUM = 600,
  SEMIBOLD = 700,
}

export enum FontSizeEnum {
  xxs = 10,
  xs = 12,
  SM = 14,
  MD = 16,
  LG = 18,
  xxl = 22,
}

export interface TypographyProps {
  fontWeight: FontWeightEnum;
  fontSize: FontSizeEnum;
  children: React.ReactNode;
  color?: string;
}

export const Typography: React.FC<TypographyProps> = ({
  fontWeight,
  fontSize,
  children,
  color,
}) => {
  return (
    <SyledTypography fontWeight={fontWeight} fontSize={fontSize} color={color}>
      {children}
    </SyledTypography>
  );
};
