import { createGlobalStyle } from "styled-components";
import { theme } from "../constants/StyledComponents";

const GlobalStyles = createGlobalStyle`
body,
html {
  margin: 0;
  font-family: "Work Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  color: ${theme.colors.black}
}
#root {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
}
.ant-table-content {
  overflow: auto hidden !important;
}
*::-webkit-scrollbar {
width: 12px;
}
*::-webkit-scrollbar-track {
background-color: #ccc;
box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
background-color: #0066ce;
border-radius: 10px;
}
.ant-modal-root .ant-modal-wrap .ant-modal > div,
.ant-modal-root .ant-modal-wrap .ant-modal > div > .ant-modal-content {
    height: 100%;
  }
`;

export default GlobalStyles;
