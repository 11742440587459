import React from "react";
import { Select } from "antd";

type CustomSelectProps<T> = {
  placeholder: string;
  value?: T | null;
  onChange?: (value: T) => void;
  options: { label: string; value: T }[];
  disabled?: boolean;
};

export const CustomSelect = <T extends string | number | undefined>({
  value,
  onChange,
  options,
  placeholder,
  disabled,
}: CustomSelectProps<T>) => (
  <Select
    disabled={disabled}
    placeholder={placeholder}
    allowClear
    style={{ width: 200 }}
    value={value ?? undefined}
    onChange={onChange}
    options={options}
  />
);
