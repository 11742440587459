import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

export const StyledHeader = styled.div`
  height: ${theme.variables.header_height};
  border-radius: ${theme.variables.border_radius_1};
  box-shadow: 0px 2px 8px 0px rgba(74, 74, 79, 0.08);
  margin: 12px 32px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  @media (max-width: ${theme.breakpoints.MD}px) {
    margin: 12px 15px;
  }
`;
