import React, { useState } from "react";
import { apiClient } from "../../api/axiosInstance";
import { StyledClients } from "./Clients.styled";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { ClientsTable } from "../../components/Tables/ClientsTable/ClientsTable";

export const Clients = () => {
  const [addModal, setAddModal] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleInvite = async () => {
    try {
      await apiClient.post("/api/invite", { email });
      setMessage("Invitation sent successfully!");
    } catch (error) {
      console.error("Error sending invitation:", error);
      setMessage("Failed to send invitation.");
    }
  };

  return (
    <StyledClients>
      <CustomModal
        onOk={() => null}
        onCancel={() => setAddModal(false)}
        open={addModal}
        height="185px"
      >
        <div style={{ height: "100%" }}>
          <h2>Invite a Client</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter client's email"
          />
          <button onClick={handleInvite}>Send Invite</button>
          {message && <p>{message}</p>}
        </div>
      </CustomModal>
      <ClientsTable setAddModal={() => setAddModal(true)} />
    </StyledClients>
  );
};
