import React from "react";
import { StyledAvatar } from "./Avatar.styled";
import { DimensionsEnum } from "../../types/appTypes";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../Typography/Typography";

type AvatarProps = {
  variant: DimensionsEnum;
  icon?: React.JSX.Element;
  initials: string;
};

export const Avatar = ({ variant, icon, initials }: AvatarProps) => {
  const dimension =
    variant === DimensionsEnum.BIG ? FontSizeEnum.MD : FontSizeEnum.SM;
  return (
    <StyledAvatar $variant={variant} className="avatar-container">
      <Typography fontWeight={FontWeightEnum.SEMIBOLD} fontSize={dimension}>
        {initials}
      </Typography>
      {icon && <div className="icon-avatar">{icon}</div>}
    </StyledAvatar>
  );
};
