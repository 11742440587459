import styled, { css, DefaultTheme } from "styled-components";
import { FontSizeEnum, FontWeightEnum, TypographyProps } from "./Typography";
import { theme } from "../../constants/StyledComponents";

const variantWeightStyles = (
  theme: DefaultTheme,

  fontWeight = FontWeightEnum.REGULAR,
) =>
  ({
    700: css`
      font-weight: 700;
    `,

    600: css`
      font-weight: 600;
    `,

    500: css`
      font-weight: 500;
    `,
  })[fontWeight];

const variantSizeStyles = (theme: DefaultTheme, fontSize = FontSizeEnum.MD) =>
  ({
    10: css`
      font-size: 10px;
    `,
    12: css`
      font-size: 12px;
    `,
    14: css`
      font-size: 14px;
    `,

    16: css`
      font-size: 16px;
    `,

    18: css`
      font-size: 18px;
    `,
    22: css`
      font-size: 22px;
    `,
  })[fontSize];

export const SyledTypography = styled.span<TypographyProps>`
  ${({ theme, fontWeight }) => variantWeightStyles(theme, fontWeight)}

  ${({ theme, fontSize }) => variantSizeStyles(theme, fontSize)}

  color: ${({ color }) => (color ? color : theme.colors.primary)};

  font-family: "Open Sans", sans-serif;
`;
