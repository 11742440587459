import styled from "styled-components";

export const StyledProjects = styled.div`
  height: 100%;
  & .ant-table-row .ant-table-cell:last-child > div {
    justify-content: flex-start;
    gap: 20px;
  }
  & .ant-select {
    width: 100%;
  }
`;
