import styled from "styled-components";

export const StyledChoosePage = styled.div`
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  & > .swiper {
    height: 100%;
  }
`;
export const StyledNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
