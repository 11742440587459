import React, { ReactNode } from "react";
import { StyledLoginCard, StyledUserIconLogin } from "./LoginCard.styled";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../../../components/Typography/Typography";
import { FaRegUser } from "react-icons/fa";
import { SIGNIN_SIGNUP } from "../../../constants/dictionary";

type LoginCardProps = {
  children: ReactNode;
};

export const LoginCard = ({ children }: LoginCardProps) => {
  return (
    <StyledLoginCard>
      <StyledUserIconLogin>
        <FaRegUser />
      </StyledUserIconLogin>
      <div style={{ textAlign: "center" }}>FirstApp</div>
      <Typography
        fontWeight={FontWeightEnum.SEMIBOLD}
        fontSize={FontSizeEnum.MD}
      >
        {SIGNIN_SIGNUP}
      </Typography>
      {children}
    </StyledLoginCard>
  );
};
