import React, { ButtonHTMLAttributes } from "react";
import { StyledButton } from "./Button.styled";
import { IconManager } from "../IconManager/IconManager";
import { IconSizeEnum, Label } from "../../types/appTypes";
import {
  FontSizeEnum,
  FontWeightEnum,
  Typography,
} from "../Typography/Typography";
import { useAppSelector } from "../../hook/Store";

export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum ButtonSizes {
  REGULAR = "regular",
  SMALL = "small",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: Label;
  variant?: ButtonVariants;
  size?: ButtonSizes;
  icon?: React.JSX.Element;
  $fullwidth?: boolean;
  color?: string;
  fontSize?: FontSizeEnum;
}

export const Button = ({
  label,
  variant = ButtonVariants.PRIMARY,
  size = ButtonSizes.REGULAR,
  icon,
  $fullwidth,
  color,
  fontSize,
  ...props
}: ButtonProps) => {
  const { language } = useAppSelector((state) => state.user);
  return (
    <StyledButton
      variant={variant}
      size={size}
      $fullwidth={$fullwidth}
      color={color}
      {...props}
    >
      {icon && (
        <IconManager
          color={color}
          size={
            size === ButtonSizes.REGULAR
              ? IconSizeEnum.SMALL
              : IconSizeEnum.XSMALL
          }
          marginRight={8}
        >
          {icon}
        </IconManager>
      )}
      <Typography
        fontWeight={FontWeightEnum.SEMIBOLD}
        fontSize={fontSize || FontSizeEnum.LG}
      >
        {label[language]}
      </Typography>
    </StyledButton>
  );
};
