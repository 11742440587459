import styled from "styled-components";
import { theme } from "../../constants/StyledComponents";

type StyledFilterButtonsProps = {
  $disabled?: boolean;
};

export const StyledFilterButtons = styled.div<StyledFilterButtonsProps>`
  display: flex;
  flex-direction: row;
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${theme.colors.grey_2};
  padding: 2px;
  border-radius: 12px;

  & span {
    padding: 3px 15px;
    border-radius: 12px;
    color: ${({ $disabled }) =>
      $disabled ? theme.colors.grey_2 : theme.colors.black};
    white-space: nowrap;
  }

  & span.active {
    background-color: ${theme.colors.purple};
    color: white;
    font-weight: 600;
  }
`;
