import styled from "styled-components";
import { theme } from "../../../../constants/StyledComponents";

export const StyledChooseContainer = styled.div`
  border: 1px solid ${theme.colors.grey_2};
  padding: 20px;
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  & > div:first-child {
    display: flex;
    flex-direction: column;
  }
  & > div > div {
    display: flex;
    flex-direction: column;
  }
  & > div > div > span:first-child {
    margin-bottom: 10px;
    display: block;
  }
  .ant-modal-body & {
    margin: 24px;
  }
`;
export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  margin-top: 50px;
  & > button {
    width: 50%;
    height: 50px;
    border-radius: 12px;
    border: none;
    cursor: pointer;
  }
  & > button:nth-child(1) {
    background-color: ${theme.colors.green};
  }
  & > button:nth-child(2) {
    background-color: ${theme.colors.red};
  }
`;
