import React from "react";
import { StyledAvatarName } from "./AvatarName.styled";
import { DirectionEnum } from "../../types/appTypes";

type AvatarNameProps = {
  children: React.ReactNode;
  direction: DirectionEnum;
};

export const AvatarName = ({ children, direction }: AvatarNameProps) => {
  return <StyledAvatarName direction={direction}>{children}</StyledAvatarName>;
};
