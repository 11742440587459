import React from "react";
import { Select } from "antd";
import { OptionItem } from "../../constants/AppConstants";
import { useAppSelector } from "../../hook/Store";

type FilterSelectProps = {
  options: OptionItem[];
  selectedOption: OptionItem | null;
  setSelectedOption: (x: OptionItem) => void;
  disabled?: boolean;
};

export const FilterSelect = ({
  options,
  selectedOption,
  setSelectedOption,
  disabled,
}: FilterSelectProps) => {
  const { language } = useAppSelector((state) => state.user);

  return (
    <Select
      style={{ width: "155px" }}
      value={selectedOption ? selectedOption.value : undefined}
      onChange={(value) => {
        const selected = options.find((option) => option.value === value);
        if (selected) {
          setSelectedOption(selected);
        }
      }}
      disabled={disabled}
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label[language]}
        </Select.Option>
      ))}
    </Select>
  );
};
