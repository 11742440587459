import React from "react";
import {
  DAILY_LABEL,
  WEEKLY_LABEL,
  BIWEEKLY_LABEL,
  BIMONTHLY_LABEL,
  QUARTERLY_LABEL,
  QUADRIMESTRAL_LABEL,
  SEMIANNUAL_LABEL,
  ANNUAL_LABEL,
  BIENNIAL_LABEL,
  TRIENNIAL_LABEL,
} from "../constants/dictionary";

export enum LanguageEnum {
  EN = "en",
  IT = "it",
}
export type Label = {
  en: string;
  it: string;
};
export enum PageNames {
  DASHBOARD = "/dashboard",
  BUY = "/buy",
  LOGIN = "/",
  CHOOSE = "/choose",
  CLIENTS = "/clients",
  PROJECTS = "/projects",
}
export interface SidebarItems {
  icon: React.ReactNode;
  label: string;
  link: PageNames | (() => void);
  otherSelected?: string;
}
export enum DimensionsEnum {
  BIG = "big",
  SMALL = "small",
}
export enum LinkSizeEnum {
  SMALL = "small",
  REGULAR = "regular",
}
export enum IconSizeEnum {
  XSMALL = "xsmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}
export enum DirectionEnum {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}
export enum RolesEnum {
  USER = "user",
  FREELANCE = "freelance",
  ADMIN = "admin",
}
export enum ApprovalEnum {
  TOAPPROVE = "to approve",
  APPROVED = "approved",
  NOTAPPROVED = "not approved",
}
export enum ModulesEnum {
  SMM = "SMM",
  ADS = "ADS",
}

export interface BuyTableItems {
  id: string;
  nameProject: string;
  modules: ModulesEnum[];
  price: number;
}
export interface BuyDataType {
  id: string;
  nameProject: string;
  modules: ModulesEnum[];
  price: number;
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof BuyTableItems;
  record: BuyTableItems;
  handleSave: (record: BuyTableItems) => void;
}
export enum CalendarFrequencyEnum {
  DAILY = "daily",
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
  BIMONTHLY = "bimonthly",
  QUARTERLY = "quarterly",
  QUADRIMESTRAL = "quadrimestral",
  SEMIANNUAL = "semiannual",
  ANNUAL = "annual",
  BIENNIAL = "biennial",
  TRIENNIAL = "triennial",
}
export enum SocialPlatformEnum {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  TWITTER = "Twitter",
  LINKEDIN = "LinkedIn",
  TIKTOK = "TikTok",
  SNAPCHAT = "Snapchat",
  PINTEREST = "Pinterest",
  YOUTUBE = "YouTube",
  REDDIT = "Reddit",
  WHATSAPP = "WhatsApp",
  TELEGRAM = "Telegram",
  DISCORD = "Discord",
  TWITCH = "Twitch",
  WECHAT = "WeChat",
  TUMBLR = "Tumblr",
}

export const CalendarFrequencyLabels: Record<CalendarFrequencyEnum, Label> = {
  [CalendarFrequencyEnum.DAILY]: DAILY_LABEL,
  [CalendarFrequencyEnum.WEEKLY]: WEEKLY_LABEL,
  [CalendarFrequencyEnum.BIWEEKLY]: BIWEEKLY_LABEL,
  [CalendarFrequencyEnum.BIMONTHLY]: BIMONTHLY_LABEL,
  [CalendarFrequencyEnum.QUARTERLY]: QUARTERLY_LABEL,
  [CalendarFrequencyEnum.QUADRIMESTRAL]: QUADRIMESTRAL_LABEL,
  [CalendarFrequencyEnum.SEMIANNUAL]: SEMIANNUAL_LABEL,
  [CalendarFrequencyEnum.ANNUAL]: ANNUAL_LABEL,
  [CalendarFrequencyEnum.BIENNIAL]: BIENNIAL_LABEL,
  [CalendarFrequencyEnum.TRIENNIAL]: TRIENNIAL_LABEL,
};
