import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../hook/Store";
import { getUser } from "../actions";
import { apiClient } from "../api/axiosInstance";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { userLogged } = useAppSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await apiClient.get("/api/current_user");
        if (res.data) {
          dispatch(getUser(res.data));
        } else {
          dispatch(getUser(false));
        }
      } catch (err) {
        console.error("Errore durante la verifica della sessione:", err);
        setError("Errore durante la verifica della sessione");
        dispatch(getUser(false));
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [dispatch]);

  return { userLogged, isLoading, error };
};
