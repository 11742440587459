import axios from "axios";
import { UpdateUserData } from "../types/apiTypes";

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BE_DOMAIN,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleLogout = async () => {
  try {
    await apiClient.get("/api/logout");
  } catch (error) {
    console.error("Error during logout:", error);
  }
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      await handleLogout();
    }

    return Promise.reject(error);
  },
);
export const deleteUser = async (userId: string): Promise<void> => {
  try {
    await apiClient.delete(`/api/users/delete/${userId}`);
    console.log("User deleted successfully");
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
export const updateUser = async (
  userId: string,
  userData: UpdateUserData,
): Promise<void> => {
  try {
    await apiClient.put(`/api/users/update/${userId}`, userData);
    console.log("User updated successfully");
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};
